import { busenessSectionsApi } from '@/apps/business-sections/api';

type INodes = {
  sections: {
    [key: string]: {
      label: string;
      id: number;
    };
  };
};

const initialState = () => ({
  q: '',

  mainPageItems: [],
  loading: false,
});

const state = initialState();
type TState = typeof state;

const getters = {
  search: (state: TState) => state.q,
  mainPageItems: (state: TState) => state.mainPageItems,
};

const actions = {
  setSearch: ({ commit }, value: string) => {
    commit('_setSearch', value);
  },

  setMainPageItems: ({ commit }, nodes: INodes) => {
    commit('_setMainPageItems', nodes);
  },

  setLoading: ({ getters, commit }, value: boolean | undefined) => {
    if (typeof value === 'undefined') {
      value = !getters.loading;
    }
    commit('_setLoading', value);
  },
};

const mutations = {
  _setSearch: (state: TState, value: string) => {
    state.q = value;
  },

  _setMainPageItems: (state: TState, items: INodes) => {
    //@ts-ignore
    const proccessedNodes = Object.entries(items.sections).map(([key, value]) => ({
      label: value.label,
      id: value.id,
      image: `${key}.svg`,
      link: `/data/${key}`,
      route: key,
    }));
    state.mainPageItems = proccessedNodes;
  },

  _setLoading: (state: TState, value: boolean) => (state.loading = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
