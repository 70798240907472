<template>
  <div class="not-found">
    <NoContent>
      Страница не найдена
      <br />
      <Button class="button" secondary @click="buttonAction">На Главную</Button>
    </NoContent>
  </div>
</template>

<script>
import NoContent from '@/core/components/NoContent/NoContent.vue';
import { Button } from '@brskl/ui-lib';
export default {
  components: {
    NoContent,
    Button,
  },

  methods: {
    buttonAction() {
      this.$router.replace('/');
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  .button {
    margin-top: 20px;
  }
}
</style>
