import { partnerApi } from '@/apps/partner/api';

const columnOrder = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

const intervalOrder = ['a_week', 'b_two_week', 'c_month', 'd_two_months', 'e_older'];

const initialState = () => ({
  analytics: [],
  rawAnalytics: {},
});

const state = initialState();

const getters = {
  analytics: (state) => state.analytics,
  rawAnalytics: (state) => state.rawAnalytics,
};

const actions = {
  getMainPageAnalytics: async ({ commit }, payload) => {
    try {
      const response = await partnerApi.getMainPageAnalytics(payload);
      commit('_setAnalytics', response);
    } catch (error) {
      if (error.status === 403) throw error;
    }
  },

  redirectToBusiness: async (_, { id, version }) => {
    console.log(id);
    console.log(version);
    const url = version === 'new' ? 'https://briskly.business' : 'https://bbo.briskly.online';

    try {
      const tokens = await partnerApi.loginAsPartner({ company_id: id });
      const queryString = Object.entries(tokens)
        .map(([key, val]) => `${key}=${val}`)
        .join('&');

      window.open(`${url}?${queryString}`, '_blank');
    } catch (error) {
      console.error(error);
    }
  },

  async redirectToOldBbo(id) {
    const Url = 'https://backoffice.briskly.online/company-authorization/login-as-partner/';

    try {
      const tokens = await partnerApi.loginAsPartner({ company_id: id });
      const queryString = Object.entries(tokens)
        .map(([key, val]) => `${key}=${val}`)
        .join('&');

      window.open(`${Url}?${queryString}`, '_blank');
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  _setAnalytics: (state, analytics) => {
    state.rawAnalytics = analytics;
    state.analytics = columnOrder
      .map((column) => {
        const items = intervalOrder
          .map((interval) => ({
            name: interval,
            items: analytics[column][interval] || [],
          }))
          .filter(({ items }) => items.length);

        return { name: column, items };
      })
      .filter(({ items }) => items.length);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
