import chat from './store/chat';
import menu from './store/menu';
import store from '@/core/store';
// import events from './events';

export const connect = (opts = {}) => {
  store.registerModule('chat$chat', chat);
  store.registerModule('chat$menu', menu);
//   events.init(store);
};
