import '@brskl/core/dist/lib.css';
import 'swiper/swiper-bundle.css';
import '@/core/styles/main.scss';
import '@/core/styles.scss';
import 'regenerator-runtime';
import '@/core/scripts/override';
import '@brskl/ui-lib/dist/lib.css';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';

import { init, modules, api } from '@brskl/core';

import app from '@/core/application';
import router from '@/core/router';
import store from '@/core/store';
import { countries, i18n, translateLocales } from '@/core/i18n';
import mainEvent from '@/core/events/main';

const VueScrollTo = require('vue-scrollto');
import * as chat from '@/apps/chat';
import * as support from '@/apps/support';
import * as content from '@/apps/content';
import * as partner from '@/apps/partner';
import * as statistics from '@/apps/statistics';
import * as device from '@/apps/device';
import * as analytics from '@/apps/analytics';
import * as channel from '@/core/channel';
import * as data from '@/apps/business-sections';
import * as exchange from '@/apps/exchange';
import * as simcard from '@/apps/sim-card';
import * as access from '@/apps/access-control';
import * as telegram from '@/apps/telegram-client';
import * as deviceAccounting from '@/apps/device-accounting';
import VueBlocksTree from 'vue3-blocks-tree';

import pushEvents from '@/core/events/push';
import { checkVersion } from '@/core/scripts/version-checker';

import PrimeVue from "primevue/config";
import primeVueLocale from '@/core/locales/primeVueRu.json';
import primeVueStyles from '@/core/styles/primeVue';

const { auth, profile } = modules;

(async () => {
  await checkVersion();



  localStorage.setItem('Tlocale', 'ru');
  api.endpoints.setCurrent(api.endpoints.internal.backoffice);

  mainEvent.init(store);

  init({
    store,
    router,
    app,
    i18n,
    PROJECT_NAME: PROJECT_NAME,
    API_URL: process.env.API_URL,
    WEBSOCKET_URL: process.env.WEBSOCKET_URL,
    IOSOCKET_URL: process.env.IOSOCKET_URL,
    NODE_ENV: process.env.NODE_ENV
  });
  channel.init(api.endpoints.current);

  pushEvents.init();

  await auth.connect({
    withPassword: true,
    countries,
    selectedCountry: 'ru',
    locales: {
      ru: { id: 1, name: 'RU', label: 'ru', locale: 'ru' },
    },
  });
  await profile.connect({ store, router });
  
  await simcard.connect();
  await support.connect();
  await content.connect();
  await partner.connect();
  await statistics.connect();
  await device.connect();
  await data.connect();
  await exchange.connect();
  await access.connect();
  await analytics.connect();
  await telegram.connect();
  await deviceAccounting.connect();
  await chat.connect();

  app.use(i18n);
  app.use(router);
  app.use(store);
  app.use(VueScrollTo, {
    container: '#groupedMessages',
    duration: 500,
    easing: 'ease',
    offset: 0,
  });
  app.use(VueBlocksTree);
  app.use(PrimeVue, {locale: primeVueLocale.ru, pt: primeVueStyles.styles})

  app.mount('#app');
})();
