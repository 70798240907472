<template>
  <div v-for='part in content'>
    <component :is='part.type' :content='part?.content' :href='part.content' />
  </div>
</template>

<script>
import BulletList from './BulletList';
import OrderedList from './OrderedList';
import Paragraph from './Paragraph';

export default {
  name: 'CardContent',
  components: { OrderedList, BulletList, Paragraph },
  props: {
    content: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang='scss' scoped src='../ConfluenceCard.scss'></style>
