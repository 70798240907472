import { difference } from "lodash";
import { mainAPI } from "../api/main";


const checkSectionNameInExeptionList = (sectionName) => {
  return ['home', 'support'].includes(sectionName)
}
const initialState = () => ({
  menu: [],
  menuSections: [],
  menuAvailableList: [
    'home',
    'chat',
    'support',
    'data',
    'content',
    'partner',
    'device',
    'exchange',
    'statistics',
    'analytics',
    'sim-card',
    'access-control',
    'telegram-client',
    'device-accounting',
  ]
});

const state = initialState();
type TState = typeof state;

const getters = {
  menu: (state, _getters, _rootState, rootGetters) => [
    ...rootGetters['chat$menu/menu'],
    ...rootGetters['support$menu/menu'],
    ...rootGetters['data$menu/menu'],
    ...rootGetters['content$menu/menu'],
    ...rootGetters['partner$menu/menu'],
    ...rootGetters['device$menu/menu'],
    ...rootGetters['exchange$menu/menu'],
    ...rootGetters['statistics$menu/menu'],
    ...rootGetters['sim-card$menu/menu'],
    ...rootGetters['access-control$menu/menu'],
    ...rootGetters['analytics$menu/menu'],
    ...rootGetters['telegram-client$menu/menu'],
    ...rootGetters['device-accounting$menu/menu'],
    ...state.menu,
  ],
  menuSections: state => state.menuSections,
  menuAvailableList: state => state.menuAvailableList

};

const actions = {
  getFullMenu: async ({ commit, dispatch, getters }) => {
    try {
      const menu = await mainAPI.getMainMenu();

      const menuKeysList = Object.keys(menu);

      if (!menuKeysList.length) {
        getters.menuAvailableList.forEach(element => {
          dispatch(`${element}$menu/removeFromList`, element, { root: true })
        });
      }

      commit('_setMenuKeysList', menuKeysList)

      difference(getters.menuAvailableList, menuKeysList).map((m) => {
        if (!checkSectionNameInExeptionList(m)) dispatch(`${m}$menu/removeFromList`, m, { root: true })
      })

      Object.entries(menu).map(([sectionKey, section]) => {
        dispatch(`${sectionKey}$menu/getChildSubheader`, section, { root: true })
      })

      Object.entries(getters.menu).forEach(([idx, value]: any) => {
        if (!value.nodes?.length) {
          //@ts-ignore
          checkSectionNameInExeptionList(value) &&
            dispatch(`${value.id}$menu/removeFromList`, value.id, { root: true })
        }
      })
    } catch (error) {
      console.log(error)
      return getters.menuAvailableList.forEach(element => {
        dispatch(`${element}$menu/removeFromList`, element, { root: true })
      });
    }
  }
}

const mutations = {
  _setMenuKeysList: (state: TState, list: string[]) => {
    state.menuSections = list
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
