const breadCrumb = [
  {
    label: 'Оборудование',
    route: { name: 'device' },
  },
  {
    label: 'Мониторинг',
    route: { name: 'device$monitoring' },
  },
  {
    label: 'Инициализация устройств',
    route: { name: 'device$init' },
  },
  {
    label: 'Мониторинг лайв',
    route: { name: 'device$monitoring_live' },
  },
  {
    label: 'Модули',
    route: { name: 'device$monitoring_live/modules' },
  },
  {
    label: 'Камеры',
    route: { name: 'device$monitoring_live/camera' },
  },
  {
    label: 'Видео он-лайн',
    route: { name: 'device$monitoring_live/camera-live' },
  },
  {
    label: 'Терминалы',
    route: { name: 'device$monitoring_live/terminals' },
  },
  {
    label: 'Старые Модули',
    route: { name: 'device$monitoring_live/legacy-module' },
  },
];

export default [
  {
    path: '/device',
    name: 'device',
    component: () => import('@/apps/device/views/Device.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
    children: [
      {
        path: '/device/device_monitoring',
        name: 'device$monitoring',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1]],
        },
        component: () => import('@/apps/device/views/Monitoring/Monitoring.vue'),
      },
      {
        path: '/device/device_init',
        name: 'device$init',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[2]],
        },
        component: () => import('@/apps/device/views/DeviceInit/DeviceInit.vue'),
      },
      {
        path: '/device/device_monitoring_live',
        name: 'device$monitoring_live',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3]],
        },
        component: () => import('@/apps/device/views/DeviceLive/DeviceLive.vue'),
      },
      {
        path: '/device/device_monitoring_live/modules',
        name: 'device$monitoring_live$modules',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3], breadCrumb[4]],
        },
        component: () => import('@/apps/device/views/DeviceLive/Modules/Modules.vue'),
      },
      {
        path: '/device/device_monitoring_live/camera',
        name: 'device$monitoring_live$camera',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3], breadCrumb[5]],
        },
        component: () => import('@/apps/device/views/DeviceLive/Camera/Camera.vue'),
      },
      {
        path: '/device/device_monitoring_live/camera-live',
        name: 'device$monitoring_live$camera-live',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3], breadCrumb[6]],
        },
        component: () => import('@/apps/device/views/DeviceLive/Video-Live/Video-Live.vue'),
      },
      {
        path: '/device/device_monitoring_live/terminals',
        name: 'device$monitoring_live$terminals',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3], breadCrumb[7]],
        },
        component: () => import('@/apps/device/views/DeviceLive/TerminalsAndLegacyModules/Terminals.vue'),
      },
      {
        path: '/device/device_monitoring_live/legacy-module',
        name: 'device$monitoring_live$legacy-module',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3], breadCrumb[8]],
        },
        component: () => import('@/apps/device/views/DeviceLive/TerminalsAndLegacyModules/Terminals.vue'),
      }
    ],
  },
];
