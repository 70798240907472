<template>
  <div>
    <div v-for='patchnote in patchNotes' v-if='patchNotes.length' style='width: 100%'>
      <ConfluenceCard class='u-mb-16'
                      style='height: 100%'
                      :title='patchnote?.title'
                      :date='patchnote?.updatedAt'
                      :text='serializeData(patchnote?.text)'
                      :overline='true'
                      v-if='patchnote?.text?.length'
      />
    </div>
  </div>
</template>

<script>
import { homeApi } from '@/core/api/home';
import ConfluenceCard from '@/core/components/ConfluenceCard/ConfluenceCard';
import serializeData from '@/core/utils/serializeConfluenceData';

export default {
  name: 'Patchnotes',
  components: { ConfluenceCard },
  data() {
    return {
      patchNotes: [],
      serializeData,
    };
  },
  async created() {
    try {
      const response = await homeApi.getPatchNotes({ type: 'bcu' });
      this.patchNotes = response.items.slice(1, response.items.length).reverse();
      console.log(JSON.parse(this.patchNotes[0].text), 'patchnotes');
      this.patchNotes.forEach(patchnote => patchnote.text = JSON.parse(patchnote.text).content);
    } catch (error) {
      console.error(error);
    }

    //this.patchNote.text = JSON.parse(this.patchNote.text).content;
  },
};
</script>

<style scoped>

</style>
