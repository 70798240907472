<template>
  <ul
    :key="i"
    v-for="(item, i) in items"
    :class="['sidebar-menu', computeLevel, { 'with-separator': isActiveItem(item) && withSeparator(item) }]"
  >
    <component
      :isMenuCollapsed="isCollapsed"
      :data-id="item.id"
      :active="isActiveItem(item)"
      @click="onClickItem($event, item)"
      :item="item"
      :is="getItemComponent(item)"
      :withMenu="!!item.nodes"
      :firstItem="i === 0"
      :lastItem="i === items.length - 1"
      :meta="item.meta || {}"
    />

    <Menu
      v-if="item.nodes && canShowNodes(item)"
      :isMenuCollapsed="isCollapsed"
      :displayMode="displayMode"
      :level="level + 1"
      :items="item.nodes"
    />
  </ul>
</template>

<script>
import Header from '@/core/components/organism/Sidebar/Header/Header.vue';
import Subheader from '@/core/components/organism/Sidebar/SubHeader/SubHeader.vue';
import Button from '@/core/components/organism/Sidebar/Button/Button.vue';
import store from '@/core/store';

const knownTypes = {
  button: 'Button',
  subheader: 'Subheader',
  header: 'Header',
};

export default {
  name: 'Menu',
  emits: ['toggleMenu', 'openMenu'],

  components: {
    Header,
    Subheader,
    Button,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    displayMode: {
      type: String,
    },

    level: {
      type: Number,
      required: true,
    },
    isMenuCollapsed: {
      type: Boolean,
    },
  },

  data() {
    return {
      showNodes: {},
      isCollapsed: false,
    };
  },

  computed: {
    computeLevel() {
      const { level } = this;
      return level <= 4 ? `level-${level}` : 'level';
    },

    isRootLevel() {
      return this.level === 0;
    },
  },

  methods: {
    getItemComponent({ type }) {
      return knownTypes[type] || knownTypes.button;
    },

    withSeparator(item) {
      if (!item.meta) return;
      return item.meta.separator;
    },

    canShowNodes(item) {
      return this.showNodes[item.title];
    },

    async onClickItem(ev, item) {
      ev.preventDefault();

      // const store = storage.get('store');

      // if (this.showNodes[item.title] && !item.force) return;

      this.items
        .filter(({ title }) => title !== item.title)
        .forEach(({ title }) => (this.showNodes[title] = undefined));

      const { action = () => true } = item;

      if (!this.showNodes[item.title]) {
        if (!item.emulatedClick) bus.emit('cancelAllRequests');
      }

      const result = await action({ store });

      const handleEndOfExecution = () => {
        const rootMenu = document.querySelector('.root-menu');

        const anyActiveRouterLink = this.items.find((item) => {
          const el = rootMenu.querySelector(`[data-id="${item.id}"]`);
          if (el)
            return el.classList.contains('router-link-active') || el.classList.contains('router-link-exact-active');
        });

        if (anyActiveRouterLink && anyActiveRouterLink.id !== item.id && item.link) {
          item.actionDone = true;
          return;
        }

        if (!this.showNodes[item.title]) this.showNodes[item.title] = true;
        item.actionDone = true;
      };

      if (item.emulatedClick) return handleEndOfExecution();

      if (result) {
        if (item.link) this.$router.push(item.link).then(handleEndOfExecution);
        else handleEndOfExecution();
      } else {
        item.actionDone = true;
      }
    },

    isActiveItem(item) {
      return this.showNodes[item.title];
    },

    waitForAction(item) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (item.actionDone) {
            item.actionDone = false;
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    },

    async tryToExpandMenu(opts = {}) {
      if (!this.isRootLevel) return;

      const {
        path = this.$route.path,
        rootMenu = document.querySelector('.root-menu'),
        iterateTarget = this.items,
      } = opts;

      let { nextElement } = opts;



      // fix menu bug 'device-accounting'.includes('device') = true
      // if (!nextElement) nextElement = iterateTarget.find((i) => path.includes(i.id));

      const activePath = [...path?.split('/')][1];

      if (!nextElement) nextElement = iterateTarget.find((i) => i.id === activePath);
      if (!nextElement) return;

      const elem = rootMenu.querySelector(`[data-id="${nextElement.id}"]`);

      if (!elem) {
        console.warn('[SIDEBAR]: cant find:', `[data-id="${nextElement.id}"]`);
        return;
      }

      nextElement.emulatedClick = true;


      await elem.click();
      await this.waitForAction(nextElement);
      delete nextElement.emulatedClick;

      if (nextElement.nodes) {
        const next = nextElement.nodes.find((i) => path.includes(i.id));
        if (!next) return;
        return this.tryToExpandMenu({
          path,
          nextElement: next,
          rootMenu,
          iterateTarget: nextElement.nodes,
        });
      }
    },

    lockedTryToExpandMenu(cb) {
      if (this.isTryToExpandMenuLocked) return;
      this.isTryToExpandMenuLocked = true;

      this.tryToExpandMenu().finally(() => {
        this.isTryToExpandMenuLocked = false;
        cb();
      });
    },

    maybeDisableShownNodes() {
      const execute = () => {
        const activeNodeNames = Object.keys(this.showNodes).filter((k) => this.showNodes[k]);

        const impostorNodeNames = [];
        activeNodeNames.forEach((activeNodeName) => {
          const r = this.$route.path;
          const item = this.items.find((i) => i.title === activeNodeName);
          if (r.includes(item.id)) return;
          impostorNodeNames.push(activeNodeName);
          this.showNodes[activeNodeName] = false;
        });
      };

      execute();

      bus.emit('shared$menu/tryToExpandMenu', execute);
    },
    checkDisplayMode() {
      switch (this.displayMode) {
        case 'desktop':
          this.isCollapsed = false;
          break;
        case 'desktop-tablet':
          this.isCollapsed = true;
        default:
          break;
      }
    },
  },

  mounted() {
    this.checkDisplayMode();
    if (this.isRootLevel) {
      this.tryToExpandMenu();
      bus.on('shared$menu/tryToExpandMenu', this.lockedTryToExpandMenu);
    }
  },

  beforeUnmount() {
    if (this.isRootLevel) {
      bus.off('shared$menu/tryToExpandMenu', this.lockedTryToExpandMenu);
    }
  },

  watch: {
    '$route.path': {
      handler: function () {
        this.maybeDisableShownNodes();
      },
    },
    displayMode: {
      handler: function () {
        this.checkDisplayMode();
      },
    },
  },
};
</script>

<style src="./Menu.scss" lang="scss" scoped></style>
