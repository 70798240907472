import { api } from '@brskl/core';
import axios from 'axios';

class PartnerAPI {
  getMainPageAnalytics = async (opts = {}) => {
    return api.endpoints.current.post({ path: 'dashboard/partner/get-list' });
  };

  loginAsPartner = async (opts) => {
    const { company_id } = opts;
    return api.endpoints.current.post({ path: 'dashboard/partner/login-as-partner', params: { company_id } });
  };

  async getLinkOnCompany(opts, repeat = true) {
    const { company_id } = opts;
    const { refresh_token, access_token } = api.tokens.getTokens();

    try {
      const data = await axios.post(
        'https://backoffice.briskly.online/company-authorization/login-as-partner/',
        { company_id },
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        },
      );

      return data;
    } catch (error) {
      if (!error.response || error.response.status !== 401) throw error;

      if (repeat) return this.getLinkOnCompany(opts, false);
    }
  };

  createComment = async (params = {}) => {
    return api.endpoints.current.post({ path: 'dashboard/partner/comment/create', params });
  };

  updateComment = async (params = {}) => {
    return api.endpoints.current.post({ path: 'dashboard/partner/comment/update', params });
  };

  deleteComment = async (params = {}) => {
    return api.endpoints.current.post({ path: 'dashboard/partner/comment/delete', params });
  };

  getCommentListByCompaniesUuids = async (params = {}) => {
    return api.endpoints.current.post({ path: 'dashboard/partner/comment/get-list-by-companies-uuids', params });  // max ~99 uuids
  };

  getCommentByCompanyUuid = async (params = {}) => {
    return api.endpoints.current.get({ path: 'dashboard/partner/comment/get-by-company-uuid', params });
  };

  getCompaniesWithComment = async (params = {}) => {
    return api.endpoints.current.post({ path: 'dashboard/partner/comment/get-companies-with-comment', params });
  };
}
export const partnerApi = new PartnerAPI();
