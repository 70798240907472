import { api } from '@brskl/core';
import { IAvailableTypes, IModule } from './types/device';
import { v4 as uuidv4 } from 'uuid';
class DeviceApi {

  bindDevice = async (opts: any) => {
    const { params } = opts;
    return api.endpoints.internal.internal.post({
      path: '/device/init',
      params,
    });
  };

  getProperties = async (opts: { init_key: string }) => {
    const path = 'dashboard/device/get-properties-by-init-key';
    const params = { init_key: opts.init_key };
    return api.endpoints.current.post({
      path,
      params,
    });
  };

  getMonitoringModule = async (opts: IModule = { device: 'misc' }) => {
    const { device, state, stores } = opts;

    const params = {
      state,
      store_id: stores,
      device,
    };

    return api.endpoints.current.post({
      path: 'dashboard/device/monitoring/get-list',
      params,
    });
  };

  public getMenuList = async () => {
    return api.endpoints.current.get({ path: 'dashboard/device/get-list' });
  };

  public getDeviceTypes = async (): Promise<IAvailableTypes> => {
    return api.endpoints.current.post({
      path: 'dashboard/device/get-available-types',
    });
  };
  // public getDeviceTypes = async (): Promise<IAvailableTypes> => {
  //   return api.endpoints.current.get({
  //     path: 'dashboard/device/get-available-types',
  //     options: { requestUUID: uuidv4() },
  //   });
  // };
  // bus.on('shared$http/request', (a) => console.log(a));
  // bus.on('shared$http/response', (a) => console.log(a));
}

export const deviceApi = new DeviceApi();
