import { bus } from '@brskl/core';

interface TChannelConstructor {
  url?: string;
  endpoint: any;
}

class ChannelHandler {
  url;
  endpoint;
  channel: WebSocket;

  constructor({ url, endpoint }: TChannelConstructor) {
    this.url = url || '/dashboard/profile/get/';
    this.endpoint = endpoint;
  }

  handler = (ev) => {
    try {
      const message = JSON.parse(ev.data);

      if (message.action) {
        bus.emit(message.action, message);
      } else if (message && message.data) {
        if (message.data.chat_message) return bus.emit('chatMessage', message.data.chat_message);
        else if (message.data.chat_status_changed)
          return bus.emit('chatStatusChanged', message.data.chat_status_changed);
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export default ChannelHandler;
