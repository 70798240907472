import menu from './store/menu';
import main from './store/main';
import store from '@/core/store';
import tables from './store/tables';
import order from './store/order';

export const connect = (opts = {}) => {
  store.registerModule('data$tables', tables);
  store.registerModule('data$order', order);
  store.registerModule('data$menu', menu);
  store.registerModule('data$main', main);
};
