const breadCrumb = [
  {
    label: 'Контент',
  },
  {
    label: 'Скидки',
    route: {name: 'content$discounts'},
  },
  {
    label: 'Бренды',
    route: {name: 'content$logos'},
  },
  {
    label: 'Доставка',
    route: {name: 'content$delivery'},
  },
  {
    label: 'Сториз',
    route: {name: 'content$stories'},
  },
  {
    label: 'Сообщения',
    route: {name: 'content$systemMessages'},
  },
  {
    label: 'Новая стори',
    route: {name: 'content$stories$new-stories'},
  },
  {
    label: 'Редактирование',
    route: {name: 'content$stories$edit-stories'},
  },
  {
    label: 'Реклама на терминале',
    route: {name: 'content$terminal-promo'},
  },
];

export default [
  {
    path: '/content',
    component: () => import('@/apps/content/views/Content.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
    children: [
      {
        path: 'discounts',
        name: 'content$discounts',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1]],
        },
        component: () => import('@/apps/content/views/Discounts/Discounts.vue'),
      },

      {
        path: 'logos',
        name: 'content$logos',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[2]],
        },
        component: () => import('@/apps/content/views/Logos/Logos.vue'),
      },
      {
        path: 'delivery',
        name: 'content$delivery',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3]],
        },
        component: () => import('@/apps/content/views/Delivery/Delivery.vue'),
        children: [
          {
            path: 'new',
            name: 'delivery-new',
            component: () => import('@/apps/content/views/Delivery/CreateDelivery/CreateDelivery.vue'),
          },
          {
            path: 'edit/:deliveryId',
            name: 'delivery-edit',
            props: true,
            component: () => import('@/apps/content/views/Delivery/CreateDelivery/CreateDelivery.vue'),
          },
        ],
      },
      {
        path: 'stories',
        name: 'content$stories',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[4]],
        },
        component: () => import('@/apps/content/views/Stories/Stories.vue'),
      },
      // children: [
      {
        path: 'stories/new',
        name: 'content$stories$new-stories',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[4], breadCrumb[6]],
        },
        component: () => import('@/apps/content/views/Stories/NewStories/NewStories.vue'),
      },
      {
        path: 'stories/edit/:storyId',
        name: 'content$stories$edit-stories',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[4], breadCrumb[7]],
        },
        component: () => import('@/apps/content/views/Stories/NewStories/NewStories.vue'),
      },

      {
        path: 'system-messages',
        name: 'content$systemMessages',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[5]],
        },
        component: () => import('@/apps/content/views/SystemMessages/SystemMessages.vue'),
      },
      {
        path: 'terminal-promo',
        name: 'content$terminal-promo',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[8]],
        },
        component: () => import('@/apps/content/views/TerminalPromo/TerminalPromo.vue'),
      },
    ],
  },
];
