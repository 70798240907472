import { api } from '@brskl/core';

type TGetChatMeta = {
  opts: { id: number };
  response: {
    type: 'employee' | 'customer' | 'company';
    funnel?: number;
    status: number;
  };
};

type TFindChat = {
  opts: { id: number; type: 'employee' | 'customer' | 'company' };
  response: { id: string | null };
};

class MainAPI {
  findCompany(opts: Record<string, any>) {
    return api.endpoints.current.get({ path: 'dashboard/company/get-list', params: opts });
  }

  getChatMeta(opts: TGetChatMeta['opts']): Promise<TGetChatMeta['response']> {
    const { id } = opts;

    return api.endpoints.current.get({ path: 'dashboard/chat/get-meta', params: { id } });
  }

  findChatByClient(opts: TFindChat['opts']): Promise<TFindChat['response']> {
    const { id, type } = opts;

    return api.endpoints.current.get({ path: 'dashboard/chat/find', params: { id, type } });
  }

  getMainMenu() {
    return api.endpoints.current.get({ path: 'dashboard/get-list' });
  }

}

export const mainAPI = new MainAPI();
