let id = 1;

const state = {
  items: [],
  visible: localStorage.getItem('notice_enabled') || "1",
};

const getters = {
  items: (state) => state.items,
  visible: (state) => state.visible,
};

const actions = {
  add: ({ commit, getters }, data) => {
    if (data.type === 'chat-message') {
      if (getters.items.find((i) => i.value.id === data.value.id)) return;
    }

    commit('_add', { id: id++, ...data });
  },

  remove: ({ commit }, id) => {
    commit('_remove', id);
  },

  removeAll: ({ commit }) => {
    commit('_removeAll');
  },

  setVisible: ({ commit }, value) => commit('_setVisible', value),
};

const mutations = {
  _add: (state, value) => {
    state.items.push(value);
  },

  _remove: (state, value) => {
    state.items = state.items.filter((v) => v.id !== value);
  },

  _removeAll: (state) => {
    state.items = [];
  },

  _setVisible: (state, value) => (state.visible = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
