const breadCrumb = [
  {
    label: 'Контроль доступа',
    route: { name: 'access-control' },
  },
  {
    label: "Администраторы",
    route: { name: 'administrator' },
  },
  {
    label: "Группы",
    route: { name: 'role' },
  },
  {
    label: "Разрешения",
    route: { name: 'permission' },
  },
  {
    label: "Администратор",
    route: { name: 'access-control$administrator' },
  },
  {
    label: "Новая группа",
    route: { name: 'access-control$role$entity', },
  },
];

export default [
  {
    path: '/access-control',
    name: 'access-control',
    redirect: '/access-control/administrator',
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
    component: () => import('@/apps/access-control/views/Access.vue'),
    children: [
      {
        path: '/access-control/administrator',
        name: 'access-control$administrator',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1]],
        },
        component: () => import('@/apps/access-control/views/Tables/Tables.vue'),
      },
      {
        path: '/access-control/administrator/entity',
        name: 'access-control$administrator$entity',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1]],
        },
        component: () => import('@/apps/access-control/views/Administrator/Administrator.vue'),

      },
      {
        path: '/access-control/role',
        name: 'access-control$role',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[2]],
        },
        component: () => import('@/apps/access-control/views/Tables/Tables.vue'),
      },
      {
        path: '/access-control/role/entity',
        name: 'access-control$role$entity',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[2]],
        },
        component: () => import('@/apps/access-control/views/Role/Role.vue'),
      },
      {
        path: '/access-control/permission',
        name: 'access-control$permission',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3]],
        },
        component: () => import('@/apps/access-control/views/Permission/Permission.vue'),
      },
    ],
  },

];
