export const ru = {
    camera_channel: 'Канал',
    camera_action: 'Отправить',
    camera_payload: 'Параметры',
    camera_command: 'Команда',
    module_channel: 'Канал',
    module_action: 'Отправить',
    module_payload: 'Параметры',
    module_command: 'Команда',
    filter_btn: "Отфильтровать",
    filter: "Фильтр",
    state: "Состояние",
    timestamp_inserting: "Время добавления",
    timestamp_last_updating: "Последние обновление",
    label_role_name: "Имя Группы",
    label_admin_name: "Имя Администратора",
    label_role_description: "Описание",
    role_createdAt: 'Время созданния',
    role_updatedAt: 'Время обновления',
    permission_createdAt: 'Время созданния',
    permission_updatedAt: 'Время обновления',
    permission_type: "Тип",
    permission_description: "Описание",
    role_type: 'Тип',
    permission_id: "ID",
    permission_name: "Имя",
    permission_email: "Email",
    permission_phone: "Телефон",
    telegram_title: "Название чата",
    telegram_chatId: "ID",
    telegram_type: 'Тип',
    access_active: "Активный",
    access_blocked: "Заблокированный ",
    telegram_first_name: "Имя",
    telegram_last_name: "Фамилия",
    telegram_username: "Имя в телеграм",
    telegram_id: "Id",
    telegram_user_id: "Id пользователя",
    telegram_is_bot: "Бот",
    two_steps_verification_password_is_required: "Требуется ввести пароль",

    week: 'Неделя',
    month: 'Месяц',
    three_months: '3 месяца',
    six_months: '6 месяцев',
    sum_orders_amount: 'По выручке',
    sum_orders_count: 'По заказам',
    sum_items_count: 'По товарам',
    period: 'Период',
    main_rate: 'Основной показатель',
    region: 'Регион',
    currency: 'Валюта',
}
