const breadCrumb = [
  {
    label: 'Сервисный портал',
  },
  {
    label: 'Шаблоны изделий',
    route: { name: 'device-accounting$templates' },
  },
  {
    label: 'Справочники',
  },
  {
    label: 'Новый шаблон',
    route: { name: 'device-accounting$templates/create' },
  },
  {
    label: 'Редактирование шаблона',
    route: { name: 'device-accounting$templates/edit' },
  },
  {
    label: 'Устройства',
    route: { name: 'device-accounting$devices' },
  },
  {
    label: 'Новое устройство',
    route: { name: 'device-accounting$devices/create' },
  },
  {
    label: 'Редактирование устройства',
    route: { name: 'device-accounting$devices/edit' },
  },
  {
    label: 'Характеристики',
    route: { name: 'device-accounting$collections/props' },
  },
  {
    label: 'Основные требования',
    route: { name: 'device-accounting$collections/basic-reqs' },
  },
  {
    label: 'Правила хранения',
    route: { name: 'device-accounting$collections/storage-rules' },
  },
  {
    label: 'Стикеры для печати',
    route: { name: 'device-accounting$collections/stickers' },
  },
  {
    label: 'Типы изделий',
    route: { name: 'device-accounting$collections/device-types' },
  },
  {
    label: 'Связанные устройства',
    route: { name: 'device-accounting$devices/links' },
  },
];

export default [
  {
    path: 'device-accounting',
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
    component: () => import('@/apps/device-accounting/views/DeviceAccounting.vue'),
    children: [
      {
        path: 'collections',
        name: 'device-accounting$collections',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[2]],
        },
        component: () => import('@/apps/device-accounting/views/Sections/Collections/Collections.vue'),
        redirect: '/device-accounting/collections/props',
        children: [
          {
            path: 'props',
            name: 'device-accounting$collections/props',
            meta: {
              breadCrumb: [breadCrumb[0], breadCrumb[2], breadCrumb[8]],
            },
            component: () => import('@/apps/device-accounting/views/Sections/Collections/Properties/Properties.vue'),
          },
          {
            path: 'basic-reqs',
            name: 'device-accounting$collections/basic-reqs',
            meta: {
              breadCrumb: [breadCrumb[0], breadCrumb[2], breadCrumb[9]],
            },
            component: () => import('@/apps/device-accounting/views/Sections/Collections/BasicReqs/BasicReqs.vue'),
          },
          {
            path: 'storage-rules',
            name: 'device-accounting$collections/storage-rules',
            meta: {
              breadCrumb: [breadCrumb[0], breadCrumb[2], breadCrumb[10]],
            },
            component: () => import('@/apps/device-accounting/views/Sections/Collections/StorageRules/StorageRules.vue'),
          },
          {
            path: 'stickers',
            name: 'device-accounting$collections/stickers',
            meta: {
              breadCrumb: [breadCrumb[0], breadCrumb[2], breadCrumb[11]],
            },
            component: () => import('@/apps/device-accounting/views/Sections/Collections/Stickers/Stickers.vue'),
          },
          {
            path: 'device-types',
            name: 'device-accounting$collections/device-types',
            meta: {
              breadCrumb: [breadCrumb[0], breadCrumb[2], breadCrumb[12]],
            },
            component: () => import('@/apps/device-accounting/views/Sections/Collections/DeviceTypes/DeviceTypes.vue'),
          },
        ],
      },
      {
        path: 'devices',
        name: 'device-accounting$devices',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[5]],
        },
        component: () => import('@/apps/device-accounting/views/Sections/Devices/Devices.vue'),
        children: [
          {
            path: 'create',
            name: 'device-accounting$devices/create',
            meta: {
              breadCrumb: [breadCrumb[0], breadCrumb[5], breadCrumb[6]],
            },
            component: () => import('@/apps/device-accounting/views/Sections/Devices/Edit/DeviceEdit.vue'),
          },
          {
            path: 'edit/:uuid',
            name: 'device-accounting$devices/edit',
            meta: {
              breadCrumb: [breadCrumb[0], breadCrumb[5], breadCrumb[7]],
            },
            component: () => import('@/apps/device-accounting/views/Sections/Devices/Edit/DeviceEdit.vue'),
          },
          {
            path: ':uuid/links',
            name: 'device-accounting$devices/links',
            meta: {
              breadCrumb: [breadCrumb[0], breadCrumb[5], breadCrumb[13]],
            },
            component: () => import('@/apps/device-accounting/views/Sections/Devices/Links/DeviceLinks.vue'),
          },
        ],
      },
      {
        path: 'templates',
        name: 'device-accounting$templates',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1]],
        },
        component: () => import('@/apps/device-accounting/views/Sections/Templates/Templates.vue'),
        children: [
          {
            path: 'create',
            name: 'device-accounting$templates/create',
            meta: {
              breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[3]],
            },
            component: () => import('@/apps/device-accounting/views/Sections/Templates/Edit/TemplateEdit.vue'),
          },
          {
            path: 'edit/:uuid',
            name: 'device-accounting$templates/edit',
            meta: {
              breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[4]],
            },
            component: () => import('@/apps/device-accounting/views/Sections/Templates/Edit/TemplateEdit.vue'),
          },
        ],
      },
    ],
  },
];
