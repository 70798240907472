<template>
  <div :class="['profile-container', `d-${displayMode}`]">
    <div class="profile-item">
      <Avatar :src="avatarSrc" class="profile-item-avatar" @click="openMenu()">
        {{ text && text[0] }}
      </Avatar>
      <router-link :to="{ name: 'profile$shared-profile' }" class="profile-item-text">
        <div class="u-color--txtFocus"> {{ text }} </div>
        <div> {{ subtext }} </div>
      </router-link>

      <div class="profile-item-icon" style="margin-right: auto; cursor: pointer" @click="toggleNotice">
        <Popover :content="infoNofice" trigger="hover" width="150px">
          <Icon :name="noticeEnabled" />
        </Popover>
      </div>

      <a href="/logout" class="profile-item-icon">
        <Icon name="icon_logout" />
      </a>
    </div>
  </div>
</template>

<script>
import { Avatar, Icon, Popover } from '@brskl/ui-lib';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { Avatar, Icon, Popover },

  emits: ['openMenu'],

  props: {
    avatarSrc: String,
    text: String,
    subtext: String,
    displayMode: String,
  },

  data() {
    return {
      currentNoticeStage: '0',
    };
  },

  created() {
    const noticeEnabled = localStorage.getItem('notice_enabled');
    if (!noticeEnabled) {
      localStorage.setItem('notice_enabled', '1');
    }
    this.currentNoticeStage = noticeEnabled;
  },

  computed: {
    ...mapGetters({
      visible: 'core$notifications/visible',
    }),

    infoNofice() {
      return this.currentNoticeStage ? 'Скрыть уведомления' : 'Показывать уведомления';
    },
    noticeEnabled() {
      return this.currentNoticeStage === '1' ? 'notifications' : 'no-notifications';
    },
  },

  methods: {
    ...mapActions({
      setVisible: 'core$notifications/setVisible',
    }),

    openMenu() {
      this.$emit('openMenu');
    },

    toggleNotice() {
      let isNoticeDisabled = localStorage.getItem('notice_enabled') || '0';
      if (isNoticeDisabled === '0') isNoticeDisabled = '1';
      else isNoticeDisabled = '0';

      localStorage.setItem('notice_enabled', isNoticeDisabled);

      this.currentNoticeStage = isNoticeDisabled;
      this.setVisible(isNoticeDisabled);
    },
  },
};
</script>

<style lang="scss" scoped src="./Profile.scss"></style>
