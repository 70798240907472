import { api } from '@brskl/core';
import { IReqTableMetaParams } from './types/interfaces';

class SimCardAPI {
  public getMenuList = async () => {
    return api.endpoints.current.get({ path: 'dashboard/sim-card/get-list' });
  };

  public getMeta = async (method: IReqTableMetaParams['method']) => {
    return api.endpoints.current.get({ path: `dashboard/sim-card/${method}/get-field-list` });
  };

  public getList = async (opts = {}, method: IReqTableMetaParams) => {
    return api.endpoints.current.get({
      path: `dashboard/sim-card/${method}/get-list`,
      params: opts,
    });
  };
}

export const simCardApi = new SimCardAPI();
