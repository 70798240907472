<template>
  <div class="preloader">
    <!--        <PreloaderIcon />-->
    <Lottie :height="100" :width="100" :options="animationData" />
  </div>
</template>

<script>
import PreloaderIcon from '@/core/components/PreloaderIcon.vue';
import { Lottie } from '@brskl/ui-lib';
import loaderAnimation from '@/assets/lottie/loader.json';

export default {
  components: {
    PreloaderIcon,
    Lottie,
  },
  data() {
    return {
      animationData: {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
</style>
