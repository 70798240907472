import { api } from '@brskl/core';


export type TMode = "amount" | "purchase" | "commission" | ""
export type TRoutes = 'company' | 'item' | 'store'
interface IGetModeMetaParams {
  route: TRoutes
  mode: TMode
}
class AnalyticsAPI {
  /*
  Tables
  */
  public getModeMeta = async (params: IGetModeMetaParams) => {
    const { route, mode = '' } = params
    return api.endpoints.current.get({
      path: `dashboard/analytics/${route}/get-fields-list`,
      params: { mode }
    })

  }

  public getMeta = async (route: TRoutes) => {
    return api.endpoints.current.get({ path: `dashboard/analytics/${route}/get-fields-list` })
  }

  public getList = async (params, route: TRoutes) => {
    let payload = {
      path: `dashboard/analytics/${route}/get-report`,
      params,
    }
    const stringToCheck = params.filters?.type && params.filters.type[0]
    if (stringToCheck && ['xlsx', 'csv'].includes(stringToCheck)) {
      payload = {
        ...payload, params: { ...payload.params, token: api.tokens.getTokens().access_token },
        //@ts-ignore
        options: { linkOnly: true },
      }
    }
    return api.endpoints.current.get(payload);
  };
  // return api.endpoints.current.get({ path: `dashboard/analytics/${route}/get-report`, params })
}

export const analyticsApi = new AnalyticsAPI();
