import { busenessSectionsApi } from '@/apps/business-sections/api';
import { IExtraReq, IRefundCreate } from '../types.api';

type TPossibleOrderStages = 'check' | 'create' | ' approve' | 'error' | 'success';

type TStackMessage = {
  data: string;
  title: string;
};

type INodes = {
  sections: {
    [key: string]: {
      label: string;
      id: number;
    };
  };
};

const initialState = () => ({
  orderRefund: {},
  orders: [],

  refundDocument: {
    pay_u_order_id: 0,
    order_id: 0,
  },

  extraPaymentDocument: {
    pay_u_order_id: 0,
    order_id: 0,
  },

  curRefundStage: 'check', // check, create, approve, error

  curExtraPaymentStage: 'check', // check, create, approve, error

  messageStack: [],
});

const state = initialState();
type TState = typeof state;

const getters = {
  orderRefund: (state: TState) => state.orderRefund,
  orderById: (state: TState) => state.orders,
  // flatenItemInOrder: (state: TState) => state.orders.map(order => {
  //   return order.items.map(item => )
  // }),

  extraPaymentDocument: (state: TState) => state.extraPaymentDocument,
  refundDocument: (state: TState) => state.refundDocument,

  curRefundStage: (state: TState) => state.curRefundStage,
  curExtraPaymentStage: (state: TState) => state.curExtraPaymentStage,

  messageStack: (state: TState) => state.messageStack,
};

const actions = {
  getOrderById: async ({ commit }, { order_id }) => {
    try {
      const result = await busenessSectionsApi.getItemById({ order_id, route: 'order' });
      commit('_setCurrentOrder', result);
    } catch (error) {
      console.log(error);
    }
  },

  orderCreate: async ({ commit }, payload) => {
    try {
      let result = await busenessSectionsApi.refundCreate(payload);
      if (result.step === 'error') {
        commit('_setMessageToStack', { data: result.data_log, title: 'Ошбика создания' });
        return commit('_setCurrentRefundStage', 'error');
      }
      if (result.step === 'can_create') {
        commit('_setMessageToStack', { data: result.data_log, title: 'Подтверждение создания' });
        return commit('_setCurrentRefundStage', 'create');
      }
      if (result.step === 'created') {
        commit('_setMessageToStack', { data: result.data_log, title: 'Документ создан' });
        commit('_setCurrentRefundStage', 'approve');
        commit('_setRefundDocument', result.data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  orderRefund: async ({ commit, dispatch }, payload) => {
    try {
      let result = await busenessSectionsApi.refundApprove(payload);
      if (result.step === 'error') {
        commit('_setMessageToStack', { data: result.data_log, title: 'Ошибка создания документа возврата' });

        return commit('_setCurrentRefundStage', 'error');
      }
      if (result.step === 'can_refund') {
        commit('_setMessageToStack', { data: result.data_log, title: 'Подтверждение возврата' });

        const nextpayload = { ...payload, mode: 1 };
        dispatch('orderRefund', nextpayload);
      }
      if (result.step === 'refunded') {
        commit('_setMessageToStack', { data: result.data_log, title: 'Возврат осуществлен' });

        return commit('_setCurrentRefundStage', 'success');
      }
    } catch (error) {
      console.log(error);
    }
  },

  orderExtra: async ({ commit }, payload) => {
    try {
      let res = await busenessSectionsApi.extraCreate(payload);
      if (res.step === 'error') {
        commit('_setMessageToStack', { data: res.data_log, title: 'Ошибка создания' });
        return commit('_setCurrentExtraPaymentStage', 'error');
      }
      if (res.step === 'can_create') {
        commit('_setMessageToStack', { data: res.data_log, title: 'Подтверждение создания' });
        return commit('_setCurrentExtraPaymentStage', 'create');
      }
      if (res.step === 'created') {
        commit('_setMessageToStack', { data: res.data_log, title: 'Документ создан' });
        commit('_setCurrentExtraPaymentStage', 'approve');
        return commit('_setextraPaymentDocument', res.data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  orderExtraApprove: async ({ commit, dispatch }, payload) => {
    try {
      let result = await busenessSectionsApi.extraApprove(payload);
      if (result.step === 'error') {
        commit('_setMessageToStack', { data: result.data_log, title: 'Ошибка создания документа доплаты' });

        return commit('_setCurrentExtraPaymentStage', 'error');
      }
      if (result.step === 'can_pay') {
        commit('_setMessageToStack', { data: result.data_log, title: 'Производится доплата...' });

        const nextpayload = { ...payload, mode: 1 };
        dispatch('orderExtraApprove', nextpayload);
      }
      if (result.step === 'paid') {
        commit('_setMessageToStack', { data: result.data_log, title: 'Доплата произведена' });

        return commit('_setCurrentExtraPaymentStage', 'success');
      }

    } catch (error) {
      console.log(error);
    }
  },

  flush: async ({ commit }) => {
    commit('_setFlush');
  },
};

const mutations = {
  _setFlush: (state: TState) => {
    Object.assign(state, initialState());
  },

  _setCurrentOrder: (state: TState, res) => state.orders = res,

  _setRefundDocument: (state: TState, data) => state.refundDocument = data,

  _setextraPaymentDocument: (state: TState, data) => state.extraPaymentDocument = data,

  _setCurrentRefundStage: (state: TState, stage: TPossibleOrderStages) => state.curRefundStage = stage,

  _setCurrentExtraPaymentStage: (state: TState, stage: TPossibleOrderStages) => state.curExtraPaymentStage = stage,

  _setMessageToStack: (state: TState, message: TStackMessage) => state.messageStack = [...state.messageStack, message],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
