// import { profileAPI } from '@/core/api/profile';
import { Init } from '@/core/data/init';


const state = {
  loading: true,
  init: Init.NotStarted,
};

const getters = {
  init: (state) => state.init,
  loading: (state) => state.loading,
};

const actions = {
  setLoading: ({ commit }, value) => {
    commit('_setLoading', value);
  },

  init: async ({ dispatch, getters }) => {
    if (getters.init === Init.InProcess || getters.init === Init.Success) return;
    const { default: router } = await import('@/core/router');
    // await dispatch('core$employeePreferences/initEmployeePreferences', null, { root: true });

    try {
      await dispatch('setLoading', true);
      await dispatch('setInit', Init.InProcess);
      await dispatch("core$menu/getFullMenu", null, { root: true });
      await dispatch('setInit', Init.Success);
      await dispatch('core$browser/setEnv', null, { root: true });
      await dispatch('setLoading', false);
    } catch (error) {
      console.error('[INIT]', error);
      dispatch('setInit', Init.NotStarted);
      router.push('/logout');
    }
  },

  setInit: ({ commit }, value) => {
    commit('_setInit', value);
  },
};

const mutations = {
  _setLoading: (state, value) => (state.loading = value),
  _setInit: (state, value) => (state.init = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
