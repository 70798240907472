
const breadCrumb = [
  {
    label: 'Воронки',
    route: 'partner$voronki'
  },
]

export default [
  {
    redirect: '/partner/voronki',
    path: '/partner',
    name: 'partner',
    meta: {
      breadCrumb: [],
    }
  },
  {
    path: '/partner/voronki',
    name: 'partner$voronki',
    component: () => import('@/apps/partner/views/Partner.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
  }
];
