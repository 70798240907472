import ContentIcon from '@/assets/img/statistic-icon.svg';
import { markRaw } from 'vue';
import { statisticApi } from '../api';

const initialState = () => ({
  charts: {},
  totalRevenueCharts: {},
  menu: [
    {
      id: 'statistics',
      title: 'Статистика',
      type: 'header',
      icon: markRaw(ContentIcon),
      // link: '/statistics',
      nodes: [],
    },
  ],
});

const state = initialState();
type TState = typeof state;

const actions = {
  setChartList: ({ commit }, options) => {
    console.log(options, 'action');
    commit('_chartList', options);
  },

  getChildSubheader: async ({ commit, }, nodes) => {
    try {
      // const nodes = await statisticApi.getMenuList();
      commit('_setChildMenus', nodes);
    } catch (error) {
      console.log(error);
      commit('_cleanThisMenu');
    }
  },

  removeFromList: async ({ commit }) => {
    commit('_cleanThisMenu');
  }
};

const mutations = {
  _chartList: (state, payload) => {
    state.charts = payload;
  },

  _setChildMenus: (state: TState, nodes) => {
    const proccessedNodes = [];
    Object.entries(nodes).forEach(([key, value]) => {
      const node = {
        id: `statistics/${key}`,
        //@ts-ignore
        title: value.label,
        type: 'subheader',
        link: `/statistics/${key}`,
        meta: {},
      };
      proccessedNodes.push(node);
    });

    const updatedMenu = {
      ...state.menu[0],
      nodes: proccessedNodes,
    };
    state.menu[0] = updatedMenu;
  },

  _cleanThisMenu: (state) => {
    state.menu = [];
  },
};
const getters = {
  menu: (state) => state.menu,
  chartsList: (s) => s.charts,
  // totalRevenue: s => {
  //   for( let list in s.charts) {
  //     console.log(list);
  //   }
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
