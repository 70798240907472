<template>
  <!--    <Icon class="preloader-icon" />-->
  <Lottie :height="100" :width="100" :options="animationData" />
</template>

<script>
import Icon from '@/assets/loader.svg';
import { Lottie } from '@brskl/ui-lib';
import loaderAnimation from '@/assets/lottie/loader.json';

export default {
  components: {
    Icon,
    Lottie,
  },
  data() {
    return {
      animationData: {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes spin {
  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.preloader-icon {
  width: 50px;
  height: 50px;
  animation: 2s ease spin infinite;
}
</style>
