import { api } from '@brskl/core';

class OrderAPI {
  getList = (opts) => {
    const { customer_id, page = 1, limit = 20 } = opts;
    return api.endpoints.current.get({ path: 'dashboard/order/get-list', params: { customer_id, page, limit } });
  };
}

export const orderAPI = new OrderAPI();
