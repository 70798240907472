<template>
  <div @click="onSearchIconClick" :class="['search', { focused, expanded: canShowInput }]">
    <div class="search__btn" @mousedown.prevent>
      <Loader v-if="loading" :black="true" class="search__icon loader" />
      <search-icon v-else :class="['search__icon', { focusedicon: focused }]" />
    </div>

    <input
      v-show="canShowInput"
      class="search__field"
      ref="search"
      :value="value"
      @input="onSearch"
      @blur="onBlur"
      @focus="onFocus"
    />
  </div>
</template>

<script>
import Loader from '@/assets/loader.svg';
import SearchIcon from '@/assets/search.svg';
import { debounce } from 'lodash';

export default {
  emits: ['search', 'input'],

  components: {
    SearchIcon,
    Loader,
  },

  data() {
    return {
      focused: false,
    };
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },

  computed: {
    canShowInput() {
      return this.focused || !!this.value.length;
    },
  },

  methods: {
    onSearch(event) {
      this.$emit('input', event);
      this.debounceSearch(event.target.value);
    },

    debounceSearch: debounce(function (value) {
      if ([1].includes(value.length)) return;

      this.$emit('search', value);
    }, 500),

    setFocus() {
      const { search } = this.$refs;
      if (!search) return;
      this.focused = true;
      search.focus();
    },

    onSearchIconClick() {
      this.focused = true;
      this.$nextTick(() => this.setFocus());
    },

    onBlur() {
      this.focused = false;
    },

    onFocus() {
      this.focused = true;
    },
  },
};
</script>

<style src="./Search.scss" lang="scss" scoped></style>
