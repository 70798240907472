import support from './store/support';
import menu from './store/menu';
import store from '@/core/store';
// import router from '@/core/router';
// import routes from './routes';
import events from './events';
// import {store as chat} from './views/NewChats/store/chats';

export const connect = (opts = {}) => {
  store.registerModule('support$support', support);
  store.registerModule('support$menu', menu);
  // store.registerModule('support$chat', chat);
  events.init(store);
};
