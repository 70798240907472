<template>
  <DefaultTemplate :displayMode='device' ref='DefaultTemplate'>
    <template #sidebar>
      <Sidebar :menu='menu' ref='sidebar' :displayMode='device' @toggleMenu='toggleMenu'
               @openMenu='openMenu'>
        <template #profile>
          <Profile
            v-if='profile'
            :avatarSrc='profile.avatar_link'
            :text='profile.first_name'
            :subtext='profile.phone'
            :displayMode='device'
            @openMenu='openMenu'
          />
        </template>
      </Sidebar>
    </template>
    <template #header>
      <Header @menuClickHadnler='headerMenuClickHandler' :displayMode='device' />
    </template>
    <template #content>
      <Notifications />
      <router-view />
    </template>
  </DefaultTemplate>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Notifications from '@/core/components/Notifications/Notifications';
import Header from '@/core/components/layouts/Header/Header.vue';
import Profile from '@/core/components/organism/Sidebar/Profile/Profile.vue';

import Sidebar from '@/core/components/organism/Sidebar/Sidebar';
import DefaultTemplate from '@/core/components/DefaultTemplate/DefaultTemplate';

export default {
  components: {
    DefaultTemplate,
    Sidebar,
    Profile,
    Header,
    Notifications,
  },

  data() {
    return {
      device: 'mobile',
    };
  },

  computed: {
    ...mapGetters({
      menu: 'core$menu/menu',
      deviceIs: 'core$browser/deviceIs',
      profile: 'shared$profile/profile',
    }),

    profile() {
      return this.$store.getters['shared$profile/profile'];
    },
  },

  mounted() {
    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize();
  },

  watch: {
    deviceIs: {
      immediate: true,
      handler(device) {
        this.device = device;
        if (device === 'tablet') return (this.device = 'desktop-tablet');
      },
    },
    $route(value) {
      const excludedRoutes = ['auth', 'auth2', 'logout'];
      const catched = excludedRoutes.find((route) => route === value.name);
      this.$refs.DefaultTemplate.toggleLayouts(catched);
    },
  },

  methods: {
    ...mapMutations({ setWindowSize: 'core$browser/setWindowSize' }),
    openMenu() {
      switch (this.device) {
        case 'desktop-tablet':
          return (this.device = 'desktop');
        case 'tablet':
          this.$refs.sidebar.openForceMenu();
        default:
          return;
      }
    },
    toggleMenu() {
      switch (this.device) {
        case 'desktop':
          return (this.device = 'desktop-tablet');
        case 'desktop-tablet':
          return (this.device = 'desktop');
        case 'tablet':
          return (this.device = 'desktop');
        case 'mobile':
          return this.headerMenuClickHandler();
        default:
          return;
      }
    },
    headerMenuClickHandler() {
      this.$refs.sidebar.toggleForceCollapse();
    },
  },
};
</script>

<style lang='scss' scoped>
.main {
  display: flex;
}

.content {
  width: 100%;
  min-height: 100vh;
  background-color: #f0f3f8;
}
</style>
