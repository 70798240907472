import { createI18n } from 'vue-i18n';
import { utils } from '@brskl/core';

import { ru } from '@/core/locales/ru';

export const countries = [
    { name: 'ru', code: 7, mask: '+7 (###) ###-##-##' },
    { name: 'id', code: 62, mask: '+62 (###) ##-##-##-##' },
];

export const translateLocales = {
    ru: { id: 1, name: 'RU', label: 'ru', locale: 'ru', icon: 'ru', displayName: 'Русский' },
};
//ISO 639-1
const locales = { ru }

const getLocale = () => {
    const preferredLang = localStorage.getItem('TLocale');
    if (!preferredLang || typeof preferredLang !== 'string') {
        const userLoacale = navigator.language.slice(0, 2);
        if (Object.keys(locales).includes(userLoacale)) {
            return userLoacale;
        } else {
            return 'ru';
        }
    }
    return preferredLang.toString();
};

const instance = createI18n({
    legacy: true,
    locale: getLocale(),
    messages: locales,
    //@ts-ignore
    fallbackLocale: window.__DEV__ ? [] : ['ru'],
    pluralizationRules: {
        ru: function (choice, lng) {
            return utils.getSlavicPluralIndex(choice);
        },
    },
});

export const getI18n = () => {
    return instance.global;
};

export { instance as i18n };
