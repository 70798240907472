import axios from 'axios';

const storageKey = '__version';

export function checkVersion() {
  if (!BRANCH) return Promise.resolve();
  const cancelTokenSource = axios.CancelToken.source();

  const currentVersion = localStorage.getItem(storageKey);

  return new Promise((resolve) => {
    const timeout = setTimeout(() => {
      cancelTokenSource.cancel();
      resolve(null);
    }, 1000);

    axios
      .get(`https://front-cache-control.briskly.dev/api/version?name=ibo&branch=${BRANCH}`, {
        cancelToken: cancelTokenSource.token,
      })
      .then(({ data }) => {
        const { version } = data;
        localStorage.setItem(storageKey, version);
        if (version !== currentVersion) window.location.reload();
      })
      .catch((error) => console.error(error))
      .finally(() => {
        clearTimeout(timeout);
        resolve(null);
      });
  });
}
