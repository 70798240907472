export interface IParetsListResponse {
  label: string;
  field_name: string;
  elem: TPossibleBackEndComponent
  options?: any;
  fetchUrl?: string; //Ссылка на получение данных для мультиселекта
  is_sortable: boolean;
  removable?: boolean
  is_hidden?: boolean


}
const defaultFormat = 'yyyy-MM-dd';

interface IFilterOptions {
  is_hidden?: boolean

  options?: boolean | {
    value: string;
    title: string;
    id: string;
    inputPlaceholder: any;
  }[];
  remoteMethod?: string;
  name: string | string[];
  label: string;
  defaultFormat?: any;
  isSpecial?: boolean
  filterStart?: string
  filterEnd?: string
  dateFormat: string
  type: TPossibleBackEndComponent

}

export type TPossibleBackEndComponent =
  'multiselect'
  | 'checkbox'
  | 'radio'
  | 'date'
  | 'date_picker_range'
  | 'input'

export const buildOptionsForElem = (value: IParetsListResponse) => {
  if (!value.options) return false;

  switch (value.elem) {
    case 'multiselect':
      return Object.entries(value.options).map(([key, value]) => ({
        value: key,
        title: value,
        id: key,
        inputPlaceholder: value,
      }));
    case 'radio':
      return Object.entries(value.options).map(([key, value]) => ({
        value: key,
        name: value,
        id: key,
      }));
    default:
      break;
  }
};

export const serialiseTableCols = (data: IParetsListResponse[], columnInjection?: any[]) => {
  const columns = columnInjection && columnInjection.length && [...columnInjection] || []
  const filterOptions = [];
  let requestFields = {};
  let filters = {};
  let sorting = {};

  data.forEach((value) => {
    const newColumn = {
      name: value.field_name,
      label: value.label,
      removable: value.removable || false,
      enableSorting: value.is_sortable || false,
    };
    //@ts-ignore
    const filterOption: IFilterOptions = {
      name: value.field_name as string | string[],
      label: value.label,
      type: value.elem,
      is_hidden: Boolean(value.is_hidden) || false,

      ...(value.fetchUrl && { remoteMethod: value.fetchUrl }),
      ...(value.options && { options: buildOptionsForElem(value) }),
    };
    if (filterOption.type === 'date_picker_range') {
      filterOption.isSpecial = true
      filterOption.filterStart = `${filterOption.name}_start`
      filterOption.filterEnd = `${filterOption.name}_end`
      filterOption.defaultFormat = defaultFormat;
      filterOption.options = [];


    }

    requestFields = { ...requestFields, [value.field_name]: `${value.field_name}` };

    filters =
      filterOption.type === 'date_picker_range'
        ? { ...filters, [`${filterOption.name}_start`]: [], [`${filterOption.name}_end`]: [] }
        : { ...filters, [value.field_name]: {} };

    sorting = { ...sorting, [value.field_name]: '' };
    columns.push(newColumn);
    filterOptions.push(filterOption);
  });
  return { columns, requestFields, filterOptions, sorting, filters };
};
