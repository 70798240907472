import { accessApi, TApiRoutes } from '@/apps/access-control/api';

export const columnInjection = (route: TApiRoutes) => {
  const admin = [
    {
      name: 'checkbox',
      disableClickEvent: true,
    },
    {
      name: 'menu',
      disableClickEvent: true,
      options: [
        {
          label: 'Блокировать Администратора',
          icon: 'icon-lock',
          action: accessApi.blockAdministrator,
          name: 'blockAdministrator'

        },
        {
          label: 'Разбокировать Администратора',
          icon: 'icon-unlock',
          action: accessApi.unblockAdministrator,
          name: 'unblockAdministrator'

        },
      ]
    },
  ]
  const role = [
    {
      name: 'checkbox',
      disableClickEvent: true,
    },
    {
      name: 'menu',
      disableClickEvent: true,
      options: [
        {
          label: 'Удалить группу',
          icon: 'icon-lock',
          action: accessApi.roleRemove,
          name: 'roleRemove'
        },
      ]
    },
  ]
  switch (route) {
    case 'administrator':
      return admin
    case 'role':
      return role;
    default:
      return []
  }
}