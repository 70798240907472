import { api } from '@brskl/core';

export interface IReqParams {
  method: 'order' | 'settings' | 'task';
}
class ContentAPI {
  public getMenuList = async () => {
    return api.endpoints.current.get({ path: 'dashboard/content/get-list' });
  };
}

export const contentAPI = new ContentAPI();
