import { TPropertyList, TStorageRuleList, TGeneralRequireList, TStickerList } from "../types";

interface TState {
  selectedProps: TPropertyList[];
  selectedRules: TStorageRuleList[];
  selectedReqs: TGeneralRequireList[];
  selectedStickers: TStickerList[];
}

const state: TState = {
  selectedProps: [],
  selectedRules: [],
  selectedReqs: [],
  selectedStickers: [],
};

const actions = {
  addProperty: ({ commit }, item: TPropertyList) => commit('_addProperty', item),
  removeProperty: ({ commit }, code: string) => commit('_removeProperty', code),

  addRule: ({ commit }, item: TStorageRuleList) => commit('_addRule', item),
  removeRule: ({ commit }, uuid: string) => commit('_removeRule', uuid),

  addReq: ({ commit }, item: TGeneralRequireList) => commit('_addReq', item),
  removeReq: ({ commit }, uuid: string) => commit('_removeReq', uuid),

  addSticker: ({ commit }, item: TStickerList) => commit('_addSticker', item),
  removeSticker: ({ commit }, uuid: string) => commit('_removeSticker', uuid),
};

const mutations = {
  _addProperty(state: TState, item: TPropertyList) {
    state.selectedProps.push(item);
  },
  _removeProperty(state: TState, code: string) {
    const ind = state.selectedProps.findIndex(i => i.code === code);
    if (ind !== -1) {
      state.selectedProps.splice(ind, 1);
    }
  },

  _addRule(state: TState, item: TStorageRuleList) {
    state.selectedRules.push(item);
  },
  _removeRule(state: TState, uuid: string) {
    const ind = state.selectedRules.findIndex(i => i.uuid === uuid);
    if (ind !== -1) {
      state.selectedRules.splice(ind, 1);
    }
  },

  _addReq(state: TState, item: TGeneralRequireList) {
    state.selectedReqs.push(item);
  },
  _removeReq(state: TState, uuid: string) {
    const ind = state.selectedReqs.findIndex(i => i.uuid === uuid);
    if (ind !== -1) {
      state.selectedReqs.splice(ind, 1);
    }
  },

  _addSticker(state: TState, item: TStickerList) {
    state.selectedStickers.push(item);
  },
  _removeSticker(state: TState, uuid: string) {
    const ind = state.selectedStickers.findIndex(i => i.uuid === uuid);
    if (ind !== -1) {
      state.selectedStickers.splice(ind, 1);
    }
  },

};

const getters = {
  selectedProps: (state: TState) => state.selectedProps,
  selectedRules: (state: TState) => state.selectedRules,
  selectedReqs: (state: TState) => state.selectedReqs,
  selectedStickers: (state: TState) => state.selectedStickers,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
