const breadCrumb = [
  {
    label: 'Статистика',
    route: { name: 'statistics' },
  },
  {
    label: 'Микромаркеты',
    route: { name: 'statistics$micromarkets' },
  },
  {
    label: 'Оценки саппорта',
    route: { name: 'statistics$feedback' },
  },
  {
    label: 'Посещаемость офиса',
    route: { name: 'statistics$office_visit' },
  },
  {
    label: 'Графики',
    route: { name: 'statistics$charts' },
  },
  {
    label: "Рейтинг",
    route: { name: 'statistics$rating' }
  }
];

export default [
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('@/apps/statistics/views/Statistics.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
    children: [
      {
        path: 'micromarkets',
        name: 'statistics$micromarkets',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1]],
        },
        component: () => import('@/apps/statistics/views/Statistics/Statistics.vue'),
      },
      {
        path: 'feedback',
        name: 'statistics$feedback',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[2]],
        },
        component: () => import('@/apps/statistics/views/Ratings/Ratings.vue'),
      },
      {
        path: 'office_visit',
        name: 'statistics$office_visit',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3]],
        },
        component: () => import('@/apps/statistics/views/OfficeVisit/OfficeVisit.vue'),
      },
      {
        path: 'charts',
        name: 'statistics$charts',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[4]],
        },
        component: () => import('@/apps/statistics/views/Charts/Charts.vue'),
      },
      {
        path: 'rating',
        name: 'statistics$rating',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[5]],
        },
        component: () => import('@/apps/statistics/views/Rating/Rating.vue'),
      },
    ],
  },
];
