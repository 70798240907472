<template>
  <ol class='patch-item'>
    <li
      v-for='point in content'
      class='patch-list ordered'
    >
      {{ point.text }}
    </li>
  </ol>
</template>

<script>
export default {
  name: 'OrderedList',
  props: { content: { type: Array, required: true } },
};
</script>

<style lang='scss' scoped src='../ConfluenceCard.scss'></style>
