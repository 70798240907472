import ExchangeIcon from '@/assets/img/exchange-icon.svg';
import { markRaw } from 'vue';
import { exchangeApi } from '../api';

const initialState = () => ({
  menu: [
    {
      id: 'exchange',
      title: 'Товарообмен',
      type: 'header',
      icon: markRaw(ExchangeIcon),
      // link: '/exchange',
      nodes: [],
    },
  ],
});

const state = initialState();
type TState = typeof state;

const getters = {
  menu: (state) => state.menu,
};

const actions = {
  getChildSubheader: async ({ commit }, nodes) => {
    try {
      // const nodes = await exchangeApi.getMenuList();
      // await dispatch('data$main/setMainPageItems', nodes, { root: true });
      commit('_setChildMenus', nodes);
    } catch (error) {
      console.log(error);
      commit('_cleanThisMenu');
    }
  },

  removeFromList: async ({ commit }) => {
    commit('_cleanThisMenu');
  }
};
const mutations = {
  _setChildMenus: (state: TState, nodes) => {
    const proccessedNodes = [];
    Object.entries(nodes).forEach(([key, value]) => {
      const node = {
        id: `exchange/${key}`,
        //@ts-ignore
        title: value.label,
        type: 'subheader',
        link: `/exchange/${key}`,
        meta: {},
      };
      proccessedNodes.push(node);
    });

    const updatedMenu = {
      ...state.menu[0],
      nodes: proccessedNodes,
    };
    state.menu[0] = updatedMenu;
  },
  _cleanThisMenu: (state) => {
    state.menu = [];
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
