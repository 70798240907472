import menu from './store/menu';
import monitoring from './store/monitoring';
import store from '@/core/store';
import deviceinit from './store/deviceinit';

export const connect = (opts = {}) => {
  store.registerModule('device$menu', menu);
  store.registerModule('device$monitoring', monitoring);
  store.registerModule('device$deviceinit', deviceinit);
};
