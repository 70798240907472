<template>
  <div class="home">
    <div v-if="patchNote" class="patchnote-wrapper">
      <ConfluenceCard
        class="u-mb-16"
        :title="patchNote?.title"
        :date="patchNote?.updatedAt"
        :text="serializeData(patchNote?.text)"
        :overline="false"
        v-if="patchNote?.text?.length"
      />
    </div>
    <Preloader v-else />
    <router-link v-if="patchNote?.text?.length" class="show-history-btn" :to="{ name: 'home$patchnotes' }">
      История обновлений
    </router-link>
  </div>
</template>

<script>
import NoContent from '@/core/components/NoContent/NoContent';
import Preloader from '@/core/views/Preloader';
import { homeApi } from '@/core/api/home';
import ConfluenceCard from '@/core/components/ConfluenceCard/ConfluenceCard.vue';
import serializeData from '@/core/utils/serializeConfluenceData';

export default {
  components: {
    ConfluenceCard,
    Preloader,
    NoContent,
  },
  data() {
    return {
      patchNote: [],
      serializeData,
    };
  },
  async created() {
    try {
      const patchNotesResponse = await homeApi.getPatchNotes({ type: 'bcu' });
      this.patchNote = patchNotesResponse.items.reverse()[0];
      this.patchNote.text = JSON.parse(this.patchNote.text).content;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style src="./Home.scss" lang="scss" scoped></style>
