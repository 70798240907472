import menu from './store/menu';
import tables from './store/tables';
import main from './store/main';
import store from '@/core/store';

export const connect = (opts = {}) => {
  store.registerModule('telegram-client$tables', tables);
  store.registerModule('telegram-client$menu', menu);
  store.registerModule('telegram-client$main', main);

};
