export default [
  {
    path: '/sim-card',
    name: 'sim-card',
    component: () => import('@/apps/sim-card/views/SimCard.vue'),
    children: [
      {
        path: '/sim-card/megafon',
        name: 'sim-card$megafon',
        component: () => import('@/apps/sim-card/views/Tables/Tables.vue'),
      },
    ],
  },
];
