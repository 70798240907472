import { api } from '@brskl/core';

export type TParamsLogin = {
  phone: string
}
export interface IUserFromAllChats {
  id: number,
  type: string
  name: string
  photo_id: number,
  is_creator: boolean


}
export interface IResponseMeta {
  total_pages: number
  total_items: number
  page: number
  limit: number
}
interface IResSuccess {
  success: boolean
}
export interface IParamsAddParticipant {
  chat_id: string
  participant_nick_name: string
}
type TChatType = 'user_chat' | "group_chat" | 'channel'
interface IChatEntity {
  id: number
  is_creator: boolean
  name: string
  photo_id: string
  type: TChatType
}
interface IChatsResponse {
  items: IChatEntity[]
  meta: IResponseMeta
}
export interface IChatRenameParams {
  title: string
  chat_id?: string
}
export interface IParamsDeleteIParticipant {
  chat_id: string
  participant_id: string
}
export interface IParamsCreateChat {
  title: string
  user_names?: string[]
}
export type TResponseCreateChat = {
  chat_id: string
}
export interface IParticipant {
  id: number,
  photo_id: number
  is_bot: boolean,
  first_name: string
  last_name?: string
  username: string
  phone: string
  // is_bot?: boolean
}
export interface ILoginResponse {
  hash: string
}

export interface IUserIDParams {
  user_id: number
}

interface TConfirmLogin extends TParamsLogin {
  code: string,
  hash: string
}
class TelegramClientAPI {
  /*
  Auth
  */

  login = (params: TParamsLogin): Promise<ILoginResponse> => {
    return api.endpoints.current.post({ path: 'dashboard/telegram-client/client/login', params });
  };

  logout = (): Promise<IResSuccess> => {
    return api.endpoints.current.get({ path: 'dashboard/telegram-client/client/logout' });
  };

  confirmLogin = (params: TConfirmLogin): Promise<IResSuccess> => {
    return api.endpoints.current.post({ path: 'dashboard/telegram-client/client/confirm-login', params });
  };

  getInfo = (): Promise<IParticipant> => {
    return api.endpoints.current.get({ path: 'dashboard/telegram-client/client/get-info' });
  };

  /*
  таблицы
  */


  public getMeta = async () => {
    return api.endpoints.current.get({ path: `dashboard/telegram-client/client/dialog/get-fields-list` })
  }

  public getList = async (params): Promise<IChatsResponse> => {
    return api.endpoints.current.get({ path: `dashboard/telegram-client/client/dialog/get-list`, params })
  }

  /*
  чаты
  */

  chatCreate = (params: IParamsCreateChat): Promise<TResponseCreateChat> => {
    return api.endpoints.current.post({ path: 'dashboard/telegram-client/group-chat/create', params });
  }

  deleteChat = (params: TResponseCreateChat): Promise<IResSuccess> => {
    return api.endpoints.current.get({ path: 'dashboard/telegram-client/group-chat/delete', params });
  }

  renameChat = (params: IChatRenameParams): Promise<any> => {
    return api.endpoints.current.post({ path: 'dashboard/telegram-client/group-chat/edit-chat-title', params });
  }
  /*
  Пользователи 
  */

  getParticipants = (params: TResponseCreateChat): Promise<IParticipant[]> => {
    return api.endpoints.current.get({ path: 'dashboard/telegram-client/group-chat/get-participants', params });
  };

  addParticipants = (params: IParamsAddParticipant): Promise<any> => {
    return api.endpoints.current.post({ path: 'dashboard/telegram-client/group-chat/add-participant', params });
  }

  deleteParticipants = (params: IParamsDeleteIParticipant): Promise<IResSuccess> => {
    return api.endpoints.current.post({ path: 'dashboard/telegram-client/group-chat/delete-participant', params });
  }

  getAllChatWithUser = (params: IUserIDParams): Promise<IUserFromAllChats> => {
    return api.endpoints.current.post({ path: 'dashboard/telegram-client/user/chat/get-all-chats-with-user', params });
  }

  deleteFromAllChats = (params: IUserIDParams): Promise<IResSuccess> => {
    return api.endpoints.current.post({ path: 'dashboard/telegram-client/user/chat/delete-from-all-chats', params });
  }

  getParticipantsByQuery = (params): Promise<IParticipant[] | []> => {
    return api.endpoints.current.get({ path: 'dashboard/telegram-client/client/user/search', params });
  }
}

export const telegramClientApi = new TelegramClientAPI();
