// import { companyAPI } from '@/core/api/company';
import { db } from '@/core/utils/indexedDb';
const state = {
  employeePreferences: {
    access_hidden_fields: [],
    store_hidden_fields: [],
    exchange_hidden_fields: [],
    sim_card_hidden_fields: [],
    analytics_hidden_fields: [],
    telegram_client_hidden_fields: [],
  },
};

const getters = {
  employeePreferences: (state) => state.employeePreferences,
  access_hidden_fields: (state) => state.employeePreferences.access_hidden_fields,
  store_hidden_fields: (state) => state.employeePreferences.store_hidden_fields,
  exchange_hidden_fields: (state) => state.employeePreferences.exchange_hidden_fields,
  sim_card_hidden_fields: (state) => state.employeePreferences.sim_card_hidden_fields,
  analytics_hidden_fields: (state) => state.employeePreferences.analytics_hidden_fields,
  telegram_client_hidden_fields: (state) => state.employeePreferences.telegram_client_hidden_fields,

  // categoriesHiddenFields: (state) => state.employeePreferences.categories_hidden_fields,
};

const actions = {
  initEmployeePreferences: async ({ commit }) => {
    const preferences = await db.getEmployeePreferences();

    commit('_setPreferences', preferences);
  },

  getEmployeePreferences: async ({ commit }) => {
    try {
      const { preferences } = [];
      // await companyAPI.getEmployeePreferences();
      // await db.getEmployeePreferences();

      commit('_setPreferences', preferences);
    } catch {}
  },

  setEmployeePreferences: async ({ state, commit }, { key, preferences }) => {
    if (!key) return console.error('The `key` parameter is required!');
    const processedPreferenece = {
      ...state.employeePreferences,
      [key]: preferences,
    };

    // await db.setEmployeePreferences(key, preferences);
    commit('_setPreferences', processedPreferenece);
    // await companyAPI.setEmployeePreferences({ key, preferences });
  },

  resetKey: async ({ state }, key) => {
    if (!key) return console.error('The `key` parameter is required!');

    // await companyAPI.setEmployeePreferences({ key, preferences: [] });
  },
};

const mutations = {
  _setPreferences: (state, preferences) => (state.employeePreferences = preferences),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
