const breadCrumb = [
  {
    route: "analytics",
    label: "Аналитика"
  },
];

export default [
  {
    path: '/analytics',
    name: 'analytics',
    redirect: '/analytics/company',
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
    component: () => import('@/apps/analytics/views/Analytics.vue'),
    children: [
      {
        path: '/analytics/item',
        name: 'analytics$item',
        meta: {
          breadCrumb: [breadCrumb[0]],
        },
        component: () => import('@/apps/analytics/views/Tables/Tables.vue'),
      },

      {
        path: '/analytics/store',
        name: 'analytics$store',
        meta: {
          breadCrumb: [breadCrumb[0]],
        },
        component: () => import('@/apps/analytics/views/Tables/Tables.vue'),
      },

      {
        path: '/analytics/company',
        name: 'analytics$company',
        meta: {
          breadCrumb: [breadCrumb[0]],
        },
        component: () => import('@/apps/analytics/views/Tables/Tables.vue'),
      },
    ],
  },

];
