<template>
  <div class="notifications">
    <template v-for="item in items" :key="item.id">
      <component
        v-if="isChatMessage(item.type)"
        :is="computeMessageComponent(item.type)"
        :item="item"
        @close="onClose"
      />
    </template>
    <div v-if="items.length > 2 && visible === '1'" class="closeButton">
      <Button @click="onCloseAll">Скрыть все уведомления</Button>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/close-icon.svg';
import { mapGetters, mapActions } from 'vuex';
import ChatMessage from '@/core/components/Notifications/ChatMessage/ChatMessage';
import RegularNotification from '@/core/components/Notifications/RegularNotification/RegularNotification';
import { Button } from '@brskl/ui-lib';

export default {
  components: {
    CloseIcon,
    ChatMessage,
    RegularNotification,
    Button,
  },

  computed: {
    ...mapGetters({
      visible: 'core$notifications/visible',
      items: 'core$notifications/items',
    }),
  },

  methods: {
    ...mapActions({
      remove: 'core$notifications/remove',
      removeAll: 'core$notifications/removeAll',
      setVisible: 'core$notifications/setVisible',
    }),

    isChatMessage(type) {
      return !(type === 'chat-message' && this.visible === '0');
    },

    computeMessageComponent(type) {
      // ChatMessage - для оповещения о новых сообщениях в чатах
      // RegularNotification - системные сообщения
      return type === 'chat-message' ? 'ChatMessage' : 'RegularNotification';
    },

    onClose(id) {
      this.remove(id);
    },

    onCloseAll() {
      this.removeAll();
      this.setVisible('');
    },
  },
};
</script>

<style src="./Notifications.scss" lang="scss" scoped></style>
