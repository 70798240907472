import { api } from '@brskl/core';

class HomeApi {
  getPatchNotes(opts) {
    const { type } = opts;
    return api.endpoints.current.get({ path: `dashboard/documentation/get?type=${type}` });
  }
}

export const homeApi = new HomeApi();
