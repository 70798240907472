
const initialState = () => ({
  isAuth: true,
  user: {}
});

const state = initialState();
type TState = typeof state;

const getters = {
  isAuth: (state: TState) => state.isAuth,
  user: (state: TState) => state.user,
};

const actions = {
  setAuth: async ({ commit }, value) => {
    commit('_setAuth', value)
  },

  setUser: async ({ commit }, user) => {
    commit('_setUser', user)
  },

  flushUser: async ({ commit }) => {
    commit('_flushUser')
  }
};

const mutations = {
  _setAuth: (state: TState, value) => state.isAuth = value,
  _setUser: (state: TState, user) => state.user = user,
  _flushUser: (state: TState) => {
    const flushed = initialState().user
    state.user = flushed
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
