import { bus } from '@brskl/core';
import router from '@/core/router';
class SupportEvent {
  _store;
  currentOpenedChatId;

  init(store) {
    this._store = store;
    bus.on('chatMessage', this.onChatMessage);
    bus.on('chatStatusChanged', this.onChatStatusChanged);
    bus.on('chatOpened', this.setCurrentOpenedChatId);
  }

  setCurrentOpenedChatId = (chatID) => {
    this.currentOpenedChatId = chatID;
  };

  onChatMessage = (proccesedMessage) => {
    const message = { ...proccesedMessage, currentOpenedChatId: this.currentOpenedChatId };
    this._store.dispatch('support$support/addMessageAction', message);
  };

  onChatStatusChanged = (message) => {
    this._store.dispatch('support$support/handleChatStatusChanged', message);
    this._store.dispatch('support$menu/updateUnassignedChatCount', router.currentRoute.value.params.funnel);
  };
}

export default new SupportEvent();
