import { deviceApi } from '@/apps/device/api';
import { IAvailableTypes } from '../types/device';

const initialState = () => ({
  availableTypes: {},
  devicePool: [],
});

const state = initialState();

type TState = typeof state;

const getters = {
  devicePool: (state: TState) => state.devicePool,
  availableTypes: (state: TState) => state.availableTypes,
};

const actions = {
  getAvailableTypes: async ({ commit }) => {
    try {
      const { types } = await deviceApi.getDeviceTypes();
      commit('_getAvailableTypes', types);
    } catch (error) {
      console.log(error);
    }
  },

  addDeviceToPool: ({ commit }, device) => {
    const processedDevice = {
      ...device,
      query: device.query.reduce((acc, cur) => ({ ...acc, [cur]: '' }), {}),
    };
    commit('_addDeviceToPool', processedDevice);
  },

  initializeDevice: async ({ commit }, params) => {
    let keys = {};
    try {
      keys = await deviceApi.bindDevice({ params });
    } catch (error) {
      keys = { ...keys, ...error };
    } finally {
      return keys;
    }
  },

  handleDeviceDisconnectFromPool: ({ commit, getters }, { poolID }) => {
    const newPool = getters.devicePool.filter((d) => d.poolID !== poolID);
    commit('_handleDeviceDisconnectFromPool', newPool);
  },
};

const mutations = {
  _addDeviceToPool: (state: TState, device) => (state.devicePool = [...state.devicePool, device]),
  _getAvailableTypes: (state: TState, types: IAvailableTypes['types']) => (state.availableTypes = types),
  _handleDeviceDisconnectFromPool: (state: TState, pool) => (state.devicePool = pool),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
