const breadCrumb = [
  {
    route: "telegram-client",
    label: "Телеграм"
  },
  {
    route: "telegram-client$chats",
    label: "Чаты"
  },
  {
    route: "telegram-client$chats$add",
    label: "Новый чат"
  },
  {
    route: "telegram-client$chats$user",
    label: "Пользователь"
  }
];

export default [
  {
    path: '/telegram-client',
    name: 'telegram-client',

    meta: {
      breadCrumb: [breadCrumb[0]],
    },
    component: () => import('@/apps/telegram-client/views/TelegramClient.vue'),
    children: [

      {
        path: '/telegram-client/chats',
        name: 'telegram-client$chats',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1],],
        },
        component: () => import('@/apps/telegram-client/views/Tables/Tables.vue'),
      },
      {
        path: '/telegram-client/chats/entity',
        name: 'telegram-client$chats$entity',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[2],],
        },
        component: () => import('@/apps/telegram-client/views/Chat/Entity.vue'),
      },
      {
        path: '/telegram-client/chats/user',
        name: 'telegram-client$chats$user',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1], breadCrumb[3],],
        },
        component: () => import('@/apps/telegram-client/views/Chat/User.vue'),
      },
    ],
  },
];
