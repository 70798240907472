<template>
  <ul class='patch-item'>
    <li v-for='point in content'
        class='patch-list bullet'>
      {{ point.text }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'BulletList',
  props: { content: { type: Array, required: true } },
  methods: {},
};
</script>

<style lang='scss' scoped src='../ConfluenceCard.scss'></style>
