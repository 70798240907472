const breadCrumb = [
  {
    label: 'Товарообмен',
    route: { name: 'exchange' },
  },
  {
    label: 'Заказы',
    route: { name: 'exchange$order' },
  },
  {
    label: 'Настройки',
    route: { name: 'exchange$settings' },
  },
  {
    label: 'Задачи',
    route: { name: 'exchange$task' },
  },
];

export default [
  {
    path: '/exchange',
    name: 'exchange',
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
    component: () => import('@/apps/exchange/views/Exchange.vue'),
    children: [
      {
        path: '/exchange/order',
        name: 'exchange$order',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[1]],
        },
        component: () => import('@/apps/exchange/views/Tables/Tables.vue'),
      },
      {
        path: '/exchange/settings',
        name: 'exchange$settings',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[2]],
        },
        component: () => import('@/apps/exchange/views/Tables/Tables.vue'),
      },
      {
        path: '/exchange/task',
        name: 'exchange$task',
        meta: {
          breadCrumb: [breadCrumb[0], breadCrumb[3]],
        },
        component: () => import('@/apps/exchange/views/Tables/Tables.vue'),
      },
    ],
  },
];
