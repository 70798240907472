const linkCheck = (textLine, storage) => {
  if (textLine?.hasOwnProperty('marks')) {
    storage.content = {
      text: textLine.text,
      href: textLine.marks[0].attrs.href,
    };
  } else storage.content = {
    text: textLine.text,
  };
};

//проверка типа параграфа
const paragraphCheck = (documentItem, documentStorage, storage) => {
  //проверяем строчку на параграф со ссылкой
  if (documentItem?.content?.length > 1) {
    documentStorage.content = [];
    documentItem.content.forEach((part) => {
      if (part.text === ' ') return;
      documentStorage.type = documentItem.type;
      if (part.hasOwnProperty('marks')) {
        documentStorage.content.push({
          text: part.text,
          href: part.marks[0].attrs.href,
        });
      } else documentStorage.content.push({ text: part.text });
    });
    storage.push(documentStorage);
  } else documentItem?.content?.forEach(textLine => {
    documentStorage.type = documentItem.type;
    linkCheck(textLine, documentStorage);
    storage.push(documentStorage);
  });
};
//проверяем объект на то, список это или нет
const listCheck = (line, documentPart, storage) => {
  documentPart.content = [];
  line?.content?.forEach(textLine => {
    documentPart.type = line.type;
    documentPart.content.push({ text: textLine.content[0].content[0].text });
  });
  storage.push(documentPart);
};

const serializeData = (page) => {
  let storage = [];
  page?.forEach(line => {
    let documentPart = {};
    if (line.type === 'paragraph') {
      paragraphCheck(line, documentPart, storage);
    } else if (line.type === 'bulletList' || line.type === 'orderedList') {
      listCheck(line, documentPart, storage);
    }
  });
  return storage;
};

export default serializeData;
