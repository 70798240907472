//@ts-nocheck
import { createRouter, createWebHistory } from 'vue-router';
import support from '@/apps/support/routes';
import content from '@/apps/content/routes';
import partner from '@/apps/partner/routes';
import statistics from '@/apps/statistics/routes';
import device from '@/apps/device/routes';
import data from '@/apps/business-sections/routes';
import exchange from '@/apps/exchange/routes';
import simcard from '@/apps/sim-card/routes';
import access from '@/apps/access-control/routes';
import analytics from '@/apps/analytics/routes';
import telegram from '@/apps/telegram-client/routes';
import deviceAccounting from '@/apps/device-accounting/routes';
import chat from '@/apps/chat/routes'

import Index from '@/core/views/Index.vue';
import Patchnotes from '@/core/views/Home/Patchnotes/Patchnotes.vue';
import Main from '@/core/views/Main.vue';
import Preloader from '@/core/views/Preloader.vue';
import ExternalRedirect from '@/core/views/ExternalRedirect.vue';
import Home from '@/core/views/Home/Home.vue';
import NotFound from '@/core/views/NotFound.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      // alias: '/home',
      component: Index,
      children: [
        {
          path: '',
          name: 'main',
          component: Main,
          redirect: '/home',

          children: [
            {
              path: '/home',
              name: 'home$home',
              component: Home,
            },
            {
              path: '/patchnotes',
              name: 'home$patchnotes',
              component: Patchnotes,
            },
            ...chat,
            ...support,
            ...data,
            ...content,
            ...partner,
            ...statistics,
            ...device,
            ...exchange,
            ...simcard,
            ...access,
            ...analytics,
            ...telegram,
            ...deviceAccounting,
          ],
        },

        {
          path: 'external_redirect',
          name: 'external-redirect',
          component: ExternalRedirect,
        },
        { path: '/:pathMatch(.*)', component: NotFound },
      ],
    },

    {
      path: '/loading',
      name: 'loading',
      component: Preloader,
    },
  ],
});

export default router;
