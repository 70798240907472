import { exchangeApi, IReqParams } from '@/apps/exchange/api';
import { IParetsListResponse, serialiseTableCols } from '@/core/utils/tableSerializeUtil';

const initialState = () => ({
  tableMeta: {
    pagination: {
      limit: 25,
      page: 1,
    },
    columns: [],
    requestFields: {},
    filters: {},
    sorting: {},
    filterOptions: [],
  },
  tableData: [],

  tableLoading: false

});

const state = initialState();
type TState = typeof state;

const getters = {
  tableMeta: (state: TState) => state.tableMeta,
  tableData: (state: TState) => state.tableData,
  tableLoading: (state: TState) => state.tableLoading,

};

const actions = {
  getMeta: async ({ commit }, method: IReqParams['method']) => {
    try {
      const proccesedMeta: IParetsListResponse[] = await exchangeApi.getMeta(method);
      const meta = serialiseTableCols(proccesedMeta);
      commit('_setTableMetaMeta', meta);
    } catch (error) {
      if (error.status === 403) throw error;
    }
    finally {
      commit("_setTableLoading", false)
    }
  },

  getList: async ({ commit }, { params, method }) => {
    try {
      commit('_setFlush');
      const response = await exchangeApi.getList(params, method);
      const meta = { pagination: response.meta };
      commit('_setTableMetaMeta', meta);
      commit('_setTableData', response.items);
    } catch (error) {
      console.log(error);
    } finally {
      commit("_setTableLoading", false)
    }
  },

  setMeta: async ({ commit }, { data, dataSet }) => {
    commit('_updateMeta', { dataSet, data });
  },
};

const mutations = {
  _setFlush: (state: TState) => {
    // const a  = initialState();
  },

  _setTableMetaMeta: (state: TState, meta) => {
    state.tableMeta = {
      ...state.tableMeta,
      columns: meta.columns ?? state.tableMeta.columns,
      requestFields: meta.requestFields ?? state.tableMeta.requestFields,
      pagination: meta.pagination ?? state.tableMeta.pagination,
      filters: meta.filters ?? state.tableMeta.filters,
      sorting: meta.sorting ?? state.tableMeta.sorting,
      filterOptions: meta.filterOptions ?? state.tableMeta.filterOptions,
    };
  },

  _setTableData: (state: TState, items) => {
    state.tableData = items;
  },

  _updateMeta: (state: TState, { data, dataSet }) => {
    state.tableMeta[dataSet] = data;
  },
  _setTableLoading: (state: TState, value: boolean) => state.tableLoading = value

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
