import { api } from '@brskl/core';

type TMessageResp = {
  message: string
}
export type TApiRoutes = 'role' | "administrator"

interface IAdministratorListParams {
  limit?: number
  page?: number
  q?: string
}


type TRoleInAdmin = {
  issuedAt: string
  name: string
}

export interface IModelItemEntity {
  checked?: boolean
  uuid: string
  name: string
  name_ru: string
  created_at: string
  updated_at: string
  children?: IModelItemEntity[]
}

export interface IModuleEntity {

}

export interface IAdministratorEntity {
  id?: string
  name: string
  duty: string
  state?: "active" | "blocked"
  email: string
  phone?: string
  password?: string
  timestamp_inserting?: string
  timestamp_last_updating?: string
  roles?: TRoleInAdmin[]
}

export interface IRoleRevokeReq {
  name: string
  administratorId: number
}

export interface IRoleEntityReq {
  name: string
  description: string
  ruleName: string
  data: string
  modules?: IModuleEntity[]
  models?: IModelItemEntity[]
}

export interface IRoleEntity extends IRoleEntityReq {
  type: number
  createdAt: string
  updatedAt: string
}

class AccessAPI {
  /*
  Tables
  */
  public getMenuList = async () => {
    return api.endpoints.current.get({ path: 'dashboard/access-control/get-list' });
  };

  public getMeta = async (route: TApiRoutes) => {
    return api.endpoints.current.get({ path: `dashboard/access-control/${route}/get-fields-list` });
  };

  public getList = async (params: IAdministratorListParams = { limit: 20, page: 1 }, route: TApiRoutes) => {
    return api.endpoints.current.get({ path: `dashboard/access-control/${route}/get-list`, params });
  };
  /*
  Common
  */

  public getListByQuery = async (params: { q: string | number }, path: TApiRoutes) => {
    return api.endpoints.current.get({ path: `dashboard/access-control/${path}/get-list-by-query`, params })
  }

  /*
  Administrator
  */
  public getAdministratorById = async (params: { id: string }): Promise<IAdministratorEntity> => {
    return api.endpoints.current.get({ path: 'dashboard/access-control/administrator/get-by-id', params });
  }

  public createAdministrator = async (params: IAdministratorEntity): Promise<IAdministratorEntity> => {
    return api.endpoints.current.post({ path: "dashboard/access-control/administrator/create", params })
  }

  public updateAdministrator = async (params: IAdministratorEntity): Promise<IAdministratorEntity> => {
    return api.endpoints.current.post({ path: "dashboard/access-control/administrator/update", params })
  }

  public blockAdministrator = async (params: { id: string }) => {
    return api.endpoints.current.post({ path: `dashboard/access-control/administrator/block`, params })
  }

  public unblockAdministrator = async (params: { id: string }) => {
    return api.endpoints.current.post({ path: `dashboard/access-control/administrator/unlock`, params })
  }

  public getRolesByAdminName = async (params: { name: string, fields: any }) => {
    return api.endpoints.current.get({ path: `dashboard/access-control/administrator/get-list-by-role-name`, params })
  }


  /*
  Roles
  */
  public getRoleByName = async (params: { name: string }): Promise<IRoleEntity> => {
    return api.endpoints.current.get({ path: `dashboard/access-control/role/get-by-name`, params })
  }

  public roleCreate = async (params: IRoleEntityReq): Promise<IRoleEntity> => {
    return api.endpoints.current.post({ path: `dashboard/access-control/role/create`, params })
  }

  public roleUpdate = async (params: IRoleEntityReq): Promise<IRoleEntity> => {
    return api.endpoints.current.post({ path: `dashboard/access-control/role/update`, params })
  }

  public roleRemove = async (params: { name: string }): Promise<TMessageResp> => {
    return api.endpoints.current.post({ path: `dashboard/access-control/role/remove`, params })
  }

  public roleAssign = async (params: IRoleRevokeReq): Promise<TMessageResp> => {
    return api.endpoints.current.post({ path: `dashboard/access-control/role/assign`, params })
  }
  public roleRevoke = async (params: IRoleRevokeReq): Promise<TMessageResp> => {
    return api.endpoints.current.post({ path: `dashboard/access-control/role/revoke`, params })
  }

  /*
    Модули
  */

  public moduleGetList = async (): Promise<IModelItemEntity[]> => {
    return api.endpoints.current.get({ path: `dashboard/access-control/permission/module/get-list` })
  }

  public moduleUpdateList = async (): Promise<TMessageResp> => {
    return api.endpoints.current.get({ path: `dashboard/access-control/permission/module/update-list` })
  }

  public moduleUpdate = async (params: { id?: number, role?: string, modules: any }): Promise<TMessageResp> => {
    return api.endpoints.current.post({ path: `dashboard/access-control/permission/module/update-assignment-list`, params })
  }

  /*
    Модели

  */
  public modeleGetList = async (): Promise<any> => {
    return api.endpoints.current.get({ path: `dashboard/access-control/permission/model/get-list` })
  }

  public modeleUpdateList = async (): Promise<TMessageResp> => {
    return api.endpoints.current.get({ path: `dashboard/access-control/permission/model/update-list` })
  }

  public modelAssign = async (params: { administratorId?: string, role?: string, models: any }): Promise<TMessageResp> => {
    return api.endpoints.current.post({ path: `dashboard/access-control/permission/model/update-assignment-list`, params })
  }
}

export const accessApi = new AccessAPI();
