<template>
  <div>
    <h2 class="main-title" v-show="!overline">Что нового</h2>
    <div :class="overline ? 'overline' : ''" class="notifications-card-wrapper">
      <div v-show="overline" class="u-mb--3">{{ convertedDate }}</div>
      <h2>
        <span class="title">{{ title }}</span>
        <span v-show="!overline" class="date">{{ convertedDate }}</span>
      </h2>
      <div class="patchnote-wrapper">
        <CardContent v-if="data.firstPart.length" :content="data.firstPart" />
        <CardContent v-else :content="data.singlePart" />
      </div>
      <Transitions>
        <div class="notifications-card-content" v-show="showMore">
          <CardContent :content="data.secondPart" />
        </div>
      </Transitions>
      <span
        v-show="data.firstPart.length && data.secondPart.length"
        class="notifications-card-showmore"
        @click="showMore = !showMore"
      >
        {{ showMore ? 'Скрыть' : 'Ещё' }}
      </span>
    </div>
  </div>
</template>

<script>
import { Transitions } from '@brskl/ui-lib';
import OrderedList from './parts/OrderedList';
import BulletList from './parts/BulletList';
import CardContent from './parts/CardContent';

export default {
  components: { CardContent, Transitions, OrderedList, BulletList },
  props: {
    title: { type: String, required: true },
    date: { type: String, required: true },
    text: { type: Object, required: true },
    overline: { type: Boolean, required: true },
  },
  data() {
    return {
      showMore: false,
      data: {
        firstPart: [],
        secondPart: [],
        singlePart: [],
      },
      elements: [],
    };
  },
  methods: {},
  computed: {
    convertedDate() {
      return new Date(this.date).toLocaleDateString('ru-RU');
    },
  },
  created() {
    if (this.text) {
      if (this.text.length > 3) {
        this.data.firstPart = [...this.text].slice(0, 3);
        this.data.secondPart = [...this.text].slice(3, this.text.length);
      } else {
        this.data.singlePart = this.text;
      }
    }
  },
};
</script>

<style lang="scss" scoped src="./ConfluenceCard.scss"></style>
