<template>
  <span>
    <a v-if='linkCheck(content)'
       class='text-link'
       :href='content.href'
       target='_blank'
    >{{ content.text }}</a>
    <span class='patch-item' v-else>{{ content.text }}</span>
    <span v-if='arrayCheck(content)' v-for='item in content'>
        <a class='text-link' v-if='linkCheck(item)' :href='item.href'>
          {{ item.text }}
        </a>
        <span v-else>{{ item.text }}</span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'Paragraph',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  methods: {
    linkCheck(item) {
      return !!item.hasOwnProperty('href');
    },
    arrayCheck(item) {
      return !!Array.isArray(item);
    },
  },
};
</script>

<style lang='scss' scoped src='../ConfluenceCard.scss'></style>
