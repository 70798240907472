import { deviceApi } from '@/apps/device/api';
import { sortBy } from 'lodash';
interface IMonitoringResponse {
  has_last_software?: boolean;
  is_online?: boolean;
  sw?: string;
  store: {
    address: string;
    name: string;
  };
  store_id?: string;
  store_key?: string;
  last_connection?: string;
}
type TStore = {
  title: string;
  value: string;
  inputPlaceholder: string;
  id: string;
};

const setStateColor = (value) => {
  if (!value.is_online && !value.has_last_software) {
    return 'error';
  }
  if (!value.is_online) {
    return 'warning';
  }
  if (!value.has_last_software) {
    return 'critical';
  }
};

// const deserialise = (data, type: string) => {
//   const stores: TStore[] = [];
//   const proccessed = Object.entries(data).reduce((acc, [key, value]: [key: string, value: IMonitoringResponse]) => {
//     const deserializeValue = {
//       header: {
//         title: value?.store.name || '',
//         subtitle: value?.store_id || '',
//         state: setStateColor(value),
//       },
//       data: [
//         {
//           title: 'Адрес торговой точки:',
//           value: value?.store.address,
//           direction: 'column',
//           render: 'text',
//         },
//         {
//           title: 'Ключ устройства:',
//           value: value.store_key,
//           direction: 'column',
//           render: 'text',
//         },
//         {
//           title: 'Последняя версия ПО:',
//           value: value.has_last_software,
//           direction: 'row',
//           render: 'icon',
//         },
//         {
//           title: 'Состояние сети:',
//           value: value.is_online ? 'В сети' : 'Не в сети',
//           direction: 'row',
//           render: 'text',
//           active: Boolean(value.is_online),
//         },
//         {
//           title: 'Тип устройства:',
//           value: type,
//           direction: 'row',
//           render: 'text',
//           active: false,
//         },
//       ],
//     };
//     const store = {
//       name: value?.store.name || '',
//       id: value?.store_id || '',
//     };
//     stores.push(store);
//     return [...acc, deserializeValue];
//   }, []);
//   return { proccessed, stores };
// };

const deserialise = (data, type) => {
  const stores: TStore[] = [];
  //@ts-ignore
  const proccessed = Object.entries(data).reduce((acc, [key, value]: [key: string, value: IMonitoringResponse]) => {
    const newItem = {
      id: value.store_id,
      name: value.store.name,
      address: value.store.address,
      store_key: value.store_key,
      has_last_software: value.has_last_software ? 'Да' : 'Нет',
      is_online: value.is_online,
      type: type,
      last_connection: value.last_connection,
      sw: value.sw,
    };
    const store = {
      title: value?.store.name || '',
      value: value?.store.name || '',
      inputPlaceholder: value?.store.name || '',
      id: value?.store_id || '',
    };
    stores.push(store);
    return [...acc, newItem];
  }, []);
  return { proccessed, stores };
};

const initialState = () => ({
  loading: false,
  terminals: [],
  modules: [],
  modulesV2: [],
  terminalsStores: [], //Вытащены для фильтрации по торговым точкам конкретного типа устройства
  modulesStores: [], //Вытащены для фильтрации по торговым точкам конкретного типа устройства
  modulesV2Stores: [], //Вытащены для фильтрации по торговым точкам конкретного типа устройства
});

const state = initialState();

const getters = {
  loading: (state) => state.loading,

  terminals: (state) => state.terminals || [],
  modules: (state) => state.modules || [],
  monitoring: (state) => [state.modules, state.terminals, state.modulesV2],
  terminalsStores: (state) => state.terminalsStores,
  modulesStores: (state) => state.modulesStores,
  modulesV2Stores: (state) => state.modulesV2Stores,
};

const actions = {
  getStatisticsAboutModule: async ({ commit }, state) => {
    commit('_setLoading', true);
    try {
      const modules = await deviceApi.getMonitoringModule({ ...state, device: 'misc' });
      const proccessedModules = sortBy(modules, ['is_online', 'has_last_software']);
      commit('_setStatisticAboutModule', proccessedModules);
    } catch (error) {
    } finally {
      commit('_setLoading', false);
    }
  },

  getStatisticsAboutModuleV2: async ({ commit }, state) => {
    commit('_setLoading', true);
    try {
      const modules = await deviceApi.getMonitoringModule({ ...state, device: 'module' });
      const proccessedModules = sortBy(modules, ['is_online', 'has_last_software']);
      commit('_setStatisticAboutModuleV2', proccessedModules);
    } catch (error) {
    } finally {
      commit('_setLoading', false);
    }
  },

  getStatisticsAboutTerminals: async ({ commit }, state) => {
    commit('_setLoading', true);

    try {
      const terminals = await deviceApi.getMonitoringModule({ ...state, device: 'terminal' });
      commit('_setStatisticAbouTerminal', terminals);
    } catch (error) {
    } finally {
      commit('_setLoading', false);
    }
  },
};

const mutations = {
  _setStatisticAboutModule: (state, modules) => {
    const { stores, proccessed } = deserialise(modules, 'Модули');
    state.modulesStores = stores;

    state.modules = proccessed;
  },
  _setStatisticAboutModuleV2: (state, modules) => {
    const { stores, proccessed } = deserialise(modules, 'Модули V2');
    state.modulesV2Stores = stores;

    state.modulesV2 = proccessed;
  },

  _setStatisticAbouTerminal: (state, terminals) => {
    const { stores, proccessed } = deserialise(terminals, 'Терминалы');
    state.terminals = proccessed;
    state.terminalsStores = stores;
  },

  _setLoading: (state, value) => {
    state.loading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
