<template>
  <teleport to="#teleport-header" v-if="teleport">
    <div class="custom-header-content" v-clickOutside="closeGlobalSearchBlock">
      <slot v-if="!!$slots.default"></slot>
      <template v-else>
        <Badge class="custom-header-content-item">
          <Button width="36px" padding="0" color="--black" v-if="btnIsShown('notifications')">
            <Icon name="notifications" class="header-icon" />
          </Button>
        </Badge>
        <!-- <Button
          width="36px"
          padding="0"
          color="--black"
          v-if="btnIsShown('search')"
          @click="openGlobalSearchBlock"
          class="custom-header-content-item"
        >
          <Icon name="search" class="header-icon" />
        </Button> -->
        <Search
          width="36px"
          padding="0"
          class="custom-header-content-item"
          :value="search"
          @input="onSearchInput"
          @search="onSearch"
          @keyup.enter="onSearch"
          v-if="btnIsShown('search')"
        />
        <Button
          width="36px"
          padding="0"
          color="--black"
          v-if="btnIsShown('options')"
          class="custom-header-content-item"
        >
          <Icon name="dots" class="header-icon" />
        </Button>
        <!-- //////// -->
        <Button
          v-if="btnIsShown('additionalBtn')"
          @click="$emit('onAdditional')"
          class="custom-header-content-item desktop"
        >
          {{ additionalBtnText }}
        </Button>
        <Button
          background="--color-btn-dark-bg"
          boxShadow="none"
          color="#fff"
          v-if="btnIsShown('actionBtn')"
          @click="$emit('onAction')"
          class="custom-header-content-item desktop"
        >
          {{ actionBtnText }}
        </Button>
        <Button
          background="--color-btn-dark-bg"
          boxShadow="none"
          color="#fff"
          v-if="btnIsShown('settingsBtn')"
          @click="$emit('onSettings')"
          class="custom-header-content-item desktop"
        >
          <Icon name="icon_settings" class="header-settings-icon" />
        </Button>
        <Button
          background="--color-btn-dark-bg"
          boxShadow="none"
          color="#fff"
          width="36px"
          padding="0"
          v-if="btnIsShown('actionBtn')"
          @click="$emit('onAction')"
          class="custom-header-content-item mobile"
        >
          <Icon name="plus" class="header-action-icon" />
        </Button>
        <!-- //////// -->

        <!-- <GlobalSearch v-if="IsGlobalSearch" /> -->
        <slot name="additional"></slot>
      </template>
    </div>
  </teleport>
</template>

<script>
import { Button, Icon, Badge } from '@brskl/ui-lib';
// import GlobalSearch from '@/core/components/GlobalSearch/GlobalSearch.vue';
import clickOutside from '@/core/directives/clickOutside';
import Search from '@/core/components/Search/Search';

const SHOWN_BTNS = ['notifications', 'search', 'options', 'actionBtn', 'additionalBtn'];

export default {
  emits: ['onAction', 'onSearchInput', 'onAdditional', 'onSearch', 'onSettings'],
  components: {
    Button,
    Icon,
    Badge,
    Search,
    // GlobalSearch
  },
  directives: {
    clickOutside,
  },
  props: {
    showButtons: {
      type: Array,
      default: () => SHOWN_BTNS,
    },
    actionBtnText: {
      type: String,
      default: '',
    },
    additionalBtnText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // IsGlobalSearch: false,
      teleport: false,
      search: '',
    };
  },
  methods: {
    openGlobalSearchBlock() {
      this.IsGlobalSearch = true;
    },

    closeGlobalSearchBlock() {
      this.IsGlobalSearch = false;
    },

    btnIsShown(name) {
      return this.showButtons.includes(name);
    },

    onSearchInput(event) {
      this.search = event.target.value;
    },

    onSearch() {
      this.$emit('onSearchInput', this.search);
    },
  },
  mounted() {
    //TODO: remove this ebaniy kostil'
    this.teleport = true;
  },
};
</script>

<style lang="scss" scoped src="./HeaderContent.scss"></style>
