import ServiceIcon from '@/assets/img/service-icon.svg';

import { markRaw } from 'vue';

const initialState = () => ({
  menu: [
    {
      id: 'device-accounting',
      title: 'Сервисный портал',
      type: 'header',
      icon: markRaw(ServiceIcon),
      meta: {},
      nodes: [
        // {
        //   id: 'collections',
        //   title: 'Справочники',
        //   type: 'subheader',
        //   meta: {
        //     unassigned_count: '',
        //   },
        //   link: '/device-accounting/collections',
        // },
        // {
        //   id: 'templates',
        //   title: 'Шаблоны',
        //   type: 'subheader',
        //   meta: {
        //     unassigned_count: '',
        //   },
        //   link: '/device-accounting/templates',
        // },
        // {
        //   id: 'devices',
        //   title: 'Устройства',
        //   type: 'subheader',
        //   meta: {
        //     unassigned_count: '',
        //   },
        //   link: '/device-accounting/Устройства',
        // },
      ],
    },
  ],
});

const state = initialState();
type TState = typeof state;

const getters = {
  menu: (state) => state.menu,
};

const actions = {
  getChildSubheader: async ({ commit }, nodes) => {


    try {
      commit('_setChildMenus', nodes);
    } catch (error) {
      console.log(error);
      commit('_cleanThisMenu');
    }
  },

  removeFromList: async ({ commit }) => {
    commit('_cleanThisMenu');
  }
};

const mutations = {
  _setChildMenus: (state: TState, nodes) => {
    const processedNodes = [];
    Object.entries(nodes).forEach(([key, value]) => {
      const node = {
        id: `device-accounting/${key}`,
        //@ts-ignore
        title: value.label,
        type: 'subheader',
        link: `/device-accounting/${key}`,
        meta: {},
      };
      processedNodes.push(node);
    });

    const updatedMenu = {
      ...state.menu[0],
      nodes: processedNodes,
    };
    state.menu[0] = updatedMenu;
  },

  _cleanThisMenu: (state) => {
    state.menu = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
