const primeVueStyles = {
    "styles": {
        "calendar": {
            "input": "input",
            "day": "calendar-day",
            "weekDay": "calendar-weekday",
            "header": "calendar-buttons",
            "title": "calendar-title",
            "tableHeaderCell": "calendar-header-item",
            "table": "calendar-table",
            "groupContainer": "calendar",
            "container": "calendar-container",
            "root": "calendar-input-root",
            "monthpicker": "calendar-dates",
            "yearpicker": "calendar-dates"
        },
        "inputtext": {
            "root": "input"
        },
        "selectButton": {
            "root": "select-button-root",
            "button": ({ context }) => ({
                class: context.active ? 'select-button active' : 'select-button'
            })
        }
    }
}

export default primeVueStyles;