/* eslint-disable no-shadow, no-param-reassign */

const state = {
  size: 0,
  isDev: false,
};

const getters = {
  size: (state) => state.size,
  isDev: (state) => state.isDev,
  deviceIs: (state) => {
    if (state.size < 767) return 'mobile';
    if (state.size < 1200) return 'tablet';
    return 'desktop';
  },
};

const actions = {
  setEnv: ({ commit }) => {
    const env = process.env.NODE_ENV === 'development';
    commit('_setEnv', env);
  },
};

const mutations = {
  setWindowSize: (state) => {
    state.size = document.documentElement.clientWidth;
  },
  _setEnv: (state, value) => (state.isDev = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
