//@ts-nocheck


import Dexie, { Table } from 'dexie';

interface field {
  id?: number,
  fields: string[]
}
export class DexieWrapper extends Dexie {
  access_hidden_fields!: Table<field>
  store_hidden_fields!: Table<field>
  exchange_hidden_fields!: Table<field>
  sim_card_hidden_fields!: Table<field>
  constructor() {
    super('CareDatabase');
    this.version(1).stores({
      access_hidden_fields: '++id, fields',
      store_hidden_fields: '++id, fields',
      exchange_hidden_fields: '++id, fields',
      sim_card_hidden_fields: '++id, fields'
    });

  }




  public async setEmployeePreferences(key?: any, fields, idx?) {
    try {

      const req = await this[key].get(1)

      console.log(req);


      req ? await this[key].put({
        fields,
        key: 1
      })
        : await this[key].add({
          fields,
        })
    } catch (error) {
      console.log(error);
    }
  }

  public async getEmployeePreferences() {
    return new Promise(async (resolve, reject) => {
      const tables = Object.values(this._allTables).map(t => t.name)


      const processArray = async (array) => {
        let settings = {}
        for (const item of array) {
          const req = await this[item].get(1)
          settings = { ...settings, [item]: req?.fields ? req.fields : [] }
        }
        return settings
      }

      const settings = await processArray(tables)
      resolve(settings)
    })

  }
}

export const db = new DexieWrapper();
// db.exchange_hidden_fields.add
// export const db = new Dexie('AppDatabase');

// db.version(1).stores({
//     songs: 'id, title'
// });

// export async function addSong(id, title,) {
//     await db.songs.add({
//         id,
//         title,
//     });
// }

// async function findSong(title, { exactMatch }) {
//     query = exactMatch ?
//         db.songs.where('title').equals(title) :
//         db.songs.where('title').startsWithIgnoreCase(title);
//     return await query.toArray();
// }

// setTimeout(() => {

//     console.log(db)
//     db.addSong(1, 'water');

//     findSong("water", { exactMatch: false }).then(songs => {
//         console.log(songs); // [{id: "1", title: "Waterloo", mp3Blob: <binary> }]
//     });
// }, 2000);
