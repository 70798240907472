import { bus } from '@brskl/core';
import router from '@/core/router';

class MainEvent {
  store;

  init(_store) {
    this.store = _store;
    bus.on('core$auth/logout', this.onTokenExpired);
    bus.on('authInit', this.onAuthInit);
    bus.on('shared$auth/tokenExpired', this.onAuthInit);

    // console.log(bus.all); //Можно использовать что бы посмотреть все евенты шины событий
  }

  onTokenExpired = () => {
    //@ts-ignore
    if (!['auth'].includes(router.currentRoute.value.name)) {
      //@ts-ignore
      router.push({ name: 'logout', params: { isTokenExpired: true } });
    }
    // }
  };

  onAuthInit = () => {
    localStorage.clear();
    router.push({ name: 'home' });
  };
}

export default new MainEvent();
